import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../css/HomeSolution.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createAPIEndpoint, ENDPOINTS } from "../../api/Index";
import { Box, CircularProgress } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function HomeSolution() {

  const [article, setArticle] = useState([]);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.article)
      .fetch()
      .then((res) => {
        setArticle(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const options = {
    items: 1,
    nav: true,
    margin: 20,
    dots: false,
    rewind: true,
    autoplay: true,
    loop: true,
    responsiveClass: true,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      }
    }
  };


  return (
    <>
      <Grid className=" py-5" container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={11} md={9}>
          <div className="solution" data-aos="fade-up" >
            <h2 className="text-primary fw-bold h2-custom" data-div-id="title" data-div-type="text">
              Nos solutions métiers
            </h2>
            <div className="text-secondary fw-normal lh-1 my-2 text-custom" data-div-id="description" data-div-type="text">
              Solutions personnalisées pour répondre aux besoins complexes et évolutifs de votre secteur
            </div>

            {article.length != 0 && (
              <OwlCarousel className="owl-theme" {...options}>
                {article.length == 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: 300,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={100} thickness={5} />
                  </Box>
                ) : (
                  article.map(
                    (item) => item.type == 0 &&
                      <div className="item" key={item.idRefArticle}>
                        <div id="Link1" type="link">
                          <div className="card img-fluid card-test" >
                            <img className="card-img-top" src={item.shortImage} alt={item.titre} style={{ width: '100%' }} />
                            <div className="col text-center">
                              <NavLink to={item.slug} className="btn btn-primary btn-block btn-test h4" onClick={() => window.scrollTo(0, 0)}><div>{item.shortTitre}</div> </NavLink>   </div>
                          </div>
                        </div>
                      </div>))}
              </OwlCarousel>)}
          </div>
        </Grid>
      </Grid>
    </>
  );


}