import { Grid, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createAPIEndpoint, ENDPOINTS } from "../../../api/Index";
import useForm from "../../../hooks/useForm";
import AjoutPartenaire from "./AjoutPartenaire";

const getFreshModel = () => ({
  logo: "",
  raisonSocial: "",
});

export default function CrudPartenaire() {
  const [listPartenaire, setListPartenaire] = useState([]);
  const [open, setOpen] = useState(false);
  const [changed, setChanged] = useState(false);
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(getFreshModel);

  useEffect(() => {
    setOpen(false);
    setChanged(false);
    createAPIEndpoint(ENDPOINTS.partenaire)
      .fetch()
      .then((res) => {
        setListPartenaire(res.data);
      })
      .catch((err) => console.log(err));
  }, [changed]);

  const handleUpdate = (row) => {
    setOpen(true);
    setValues({
      logo: row.logo,
      raisonSocial: row.raisonSocial
    })
  };

  const handleDelete = (id) => {
    createAPIEndpoint(ENDPOINTS.partenaire)
      .delete(id)
      .then((res) => {
        setChanged(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div style={{ marginTop: 30 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5">
              <div id="Titre" type="text">
                {" "}
                <h1 className="text-center text-primary pulse animated">
                  Crud partenaire
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={9} xs={11} className="d-flex flex-row-reverse">
            <a onClick={() => setOpen(true)} className="btn btn-primary mb-2">
              Ajouter Partenaire
            </a>
            <AjoutPartenaire
              open={open}
              setOpen={setOpen}
              setChanged={setChanged}
              values={values}
            />
          </Grid>
          <Grid item md={9} xs={11}>
            <table className="table table-striped gs-7 gy-7 gx-7">
              <thead>
                <tr className="fw-bolder fs-6 text-gray-800">
                  <th>Id Partenaire</th>
                  <th>Logo</th>
                  <th>Raison Sociale</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {listPartenaire.map((row) => (
                  <tr key={row.idRefPartenaire}>
                    <td>{row.idRefPartenaire}</td>
                    <td>{row.logo}</td>
                    <td>{row.raisonSocial}</td>
                    <td>
                      <Button
                        className="btn btn-icon btn-warning btn-sm me-5  mx-2"
                        onClick={() => handleUpdate(row)}
                      >
                        <i class="fa-solid fa-pen"></i>
                      </Button>
                      <Button
                        className="btn btn-icon btn-danger btn-sm me-5"
                        onClick={() => handleDelete(row.idRefPartenaire)}
                      >
                        <i class="fa-solid fa-trash-can"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
