import React, { useEffect, useState } from "react";
import { createAPIEndpoint, ENDPOINTS } from "../api/Index";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";

export default function CataloguesCard(props) {
  return (
    <div className="mt-4">
      {props.Catalogues.map((item) => (
        <div className="card-custom my-4" data-aos="zoom-in">
          <div className="row no-gutters">
            <div className="col-md-4 card-img-col">
              <img src={item.image} alt="Card" className="card-img-top mt-4 p-3 img-fluid" />
            </div>
            <div className="col-md-8 card-text-col">
              <div className="card-body d-flex flex-column h-100">
                <h3 className="card-title h3-custom text-primary mb-4">{item.titre}</h3>
                <h5 className="card-subtitle h4-custom mb-2 text-muted">
                  {item.sous_titre}
                </h5>
                <p className="card-text text-custom">{item.description}</p>
                <div className="mt-auto ml-auto">
                  <a href={item.file}
                    download className="filter-button-card text-custom text-white">
                    Télécharger la version PDF <DownloadIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
