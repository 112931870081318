import Grid from "@mui/material/Grid";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../css/Produits.css";
import { useEffect, useState } from "react";
import { createAPIEndpoint, ENDPOINTS } from "../../api/Index";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";

export default function Produits() {
  const [article, setArticle] = useState([]);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.article)
      .fetch()
      .then((res) => {
        setArticle(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const options = {
    loop: true,
    margin: 20,
    nav: true,
    dots: false,
    responsiveClass: true,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <>
      <Grid
        className=" pb-5"
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={11} md={9}>
          <div className="produit" data-aos="zoom-in">
            <h2
              className="text-primary fw-bold h2-custom"
              data-div-id="title"
              data-div-type="text"
            >
              Nos produits
            </h2>
            <div
              className="text-secondary fw-normal lh-1 my-2 text-custom"
              data-div-id="description"
              data-div-type="text"
              style={{ paddingBottom: "25px" }}
            >
              Nous sommes engagés dans la promotion des meilleures technologies
              du marché, adaptées au contexte des projets. Notre gamme de
              produits est proposée sur la base de critères technologiques, mais
              pas seulement. Nous attachons une importance capitale à la
              relation avec le constructeur, son support, la disponibilité de
              pièces de rechange, la conformité aux normes et la maitrise du
              circuit d'approvisionnement. Nous privilégions également les
              équipements que nous pouvons supporter et maintenir afin de
              minimiser le TCO (Total Cost Ownership ou Coût Total de Pocession)
              et garantir la perenité des solutions proposées.
            </div>
            {article.length != 0 && (
              <OwlCarousel className="owl-theme" {...options}>
                {article.length == 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: 300,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={100} thickness={5} />
                  </Box>
                ) : (
                  article.map(
                    (item) => item.type == 1 &&
              <div className="item" key={item.idRefArticle}>
                <div id="Link1" type="link" >
                  <div className="card img-fluid" style={{height:"220px"}}>
                    <img
                      className="card-img-top rounded"
                      style={{minHeight:"170px"}}
                      src={item.shortImage}
                      alt={item.titre}
                    />
                    <div className="col h4 text-center text-primary mt-0 mx-auto btn btn-text">
                      <NavLink
                        style={{ textdecoration: "none"}}
                        to={item.slug}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        {item.shortTitre}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>))}
            </OwlCarousel>)}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
