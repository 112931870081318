import React, { useEffect } from 'react';
import Navigation from './Navigation';
import "../root/main.css";
import { Link } from 'react-router-dom';

function HomeOneHeader({
    action,
    className,
}) {
    useEffect(() => {
        document.addEventListener('scroll', () => {
            const element = document.querySelector('.appie-sticky');
            if (element) {
                if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                    element.classList.add('sticky');
                } else {
                    element.classList.remove('sticky');
                }
            }
        });
    });
    return (
        <header className={`appie-header-area appie-sticky`}>
            <div className="container">
                <div className="header-nav-box">
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                            <div className="appie-logo-box" onClick={() => window.scrollTo(0, 0)}>
                                    <Link to="/">
                                        <img src="/images/logo.webp"  height="32" alt="logo teledyne" />
                                    </Link>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-1 col-sm-1  order-sm-2 d-flex flex-row-reverse">
                            <div className="appie-header-main-menu">
                                <Navigation/>
                            </div>
                        </div>
                        <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                            <div className="appie-btn-box text-right">
                                <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                    id="canvas_open"
                                >
                                    <i className="fa fa-bars"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HomeOneHeader;
