import Grid from "@mui/material/Grid";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../css/Partenaires.css";

export default function UseCase(props) {
  const options = {
    navigation: true, // Show next and prev buttons
    autoplay: true,
    loop: true,
    slideTransition : 'fade',
    paginationSpeed: 400,
    items: 1,
    itemsDesktop: false,
    itemsDesktopSmall: false,
    itemsTablet: false,
    itemsMobile: false,
  };

  return (
    <>
      <Grid className="pt-3 mt-2" container alignItems="center" justifyContent="center">
        <Grid item xs={11} md={9}>
          <div className="partenaires" data-aos="zoom-in">
            <h2
              className="text-primary h2-custom"
              data-lfr-editable-id="title"
              data-lfr-editable-type="text"
            >
              Quelques exemples d’utilisation
            </h2>
            <div class="m-2 py-2 text-custom">{props.Txt}</div>
            <OwlCarousel className="owl-theme" {...options}>
              {props.list.map((item) => (
                <div
                  className="item img-fluid"
                >
                  <img
                    src={item.image}
                    alt={item.description}
                    style={{ maxWidth: "auto", maxHeight: "600px"}}
                  />
                </div>
              ))}
            </OwlCarousel>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
