import Grid from "@mui/material/Grid";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../css/Confiance.css";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
export default function Confiance(props) {
  const { confiance } = props;
  const options = {
    loop: true,
    nav: false,
    navigation: false,
    margin: 10,
    dots: false,
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 4,
      },
      600: {
        items: 7,
      },
      1000: {
        items: 10,
      },
    },
  };

  return (
    <>
      <Grid
        className="py-5"
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={11} md={9}>
          <div className="confiance " data-aos="zoom-in">
            <h2
              className="text-primary fw-bold h2-custom mb-4"
              data-lfr-editable-id="title"
              data-lfr-editable-type="text"
            >
              Ils nous font confiance
            </h2>

            {confiance.length != 0 && (
              <OwlCarousel className="owl-theme" {...options}>
                {confiance.length == 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: 300,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={100} thickness={5} />
                  </Box>
                ) : (
                  confiance.map(
                    (item) =>
                      item.type == 0 && (
                        <div className="item border-0" key={item.idRefSociete}>
                          <div className="card img-fluid">
                            <img
                              className="card-img-top rounded"
                              src={item.logo}
                              alt={item.titre}
                              style={{
                                width: "100%",
                                border: "1px  solid #1B4A76",
                              }}
                            />
                          </div>
                        </div>
                      )
                  )
                )}
              </OwlCarousel>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
