import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { createAPIEndpoint, ENDPOINTS } from "../api/Index";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

export default function Produits() {
  const [article, setArticle] = useState([]);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GetProduits)
      .fetch()
      .then((res) => {
        setArticle(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nos Produits d’identification et traçabilité | Terminaux mobiles, scanners de code à barres, imprimantes et plus</title>
        <meta name="description" content="Une gamme des meilleures technologies du marché, adaptées au contexte des projets : des imprimantes d’étiquettes, des scanners de code à barres, des terminaux embarqués, des terminaux mobiles, des logiciels d’amélioration logistique, des tablettes industrielles, des infrastructures WI-FI et des consommables pour impression."/>
      </Helmet>
      <div className="blue-container">
        <div className="row">
          <div className="col-md-12">
            <div id="Titre" type="text">
              {" "}
              <h1 className="text-center text-warning pulse animated title-custom">
                Nos produits
              </h1>
            </div>
            <div id="Description" type="text">
              {" "}
              <p className="text-center text-white pulse animated text-custom">
                Nous sommes engagés dans la promotion des meilleures
                technologies du marché, adaptées au contexte des projets. Notre
                gamme de produits est proposée sur la base de critères
                technologiques, mais pas seulement. Nous attachons une
                importance capitale à la relation avec le constructeur, son
                support, la disponibilité de pièces de rechange, la conformité
                aux normes et la maitrise du circuit d'approvisionnement. Nous
                privilégions également les équipements que nous pouvons
                supporter et maintenir afin de minimiser le TCO (Total Cost
                Ownership ou Coût Total de Pocession) et garantir la perenité
                des solutions proposées.
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item md={10} xs={11} className="py-4">
          {article.length == 0 ? (
            <Box
              sx={{
                display: "flex",
                height: 300,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={100} thickness={5} />
            </Box>
          ) : (
            article.map((item) =>
              item.rtl ? (
                <div
                  key={item.idRefArticle}
                  class="col-md-12 mb-2 rounded"
                  data-aos="fade"
                  style={{
                    backgroundImage: "URL('" + item.image + "')",
                    width: "auto",
                    paddingTop: "25px",
                    paddingBottom: "25px",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <h2 class="text-primary h2-custom">
                    {" "}
                    {item.titre} <br />
                  </h2>
                  <p
                    class="text-justify text-primary text-custom produit-sm"
                    style={{ maxWidth: "560px" }}
                  >
                    {" "}
                    {item.description}
                    <br />
                  </p>
                  <div class="row">
                    <div class="col" style={{ maxWidth: "590px" }}>
                      <div>
                        <NavLink
                          className="btn btn-primary shadow-none text-white text-center d-block float-right font-weight-bold"
                          type="button"
                          style={{ maxWidth: "560px" }}
                          to={item.slug}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          En savoir plus
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  key={item.idRefArticle}
                  class="col-md-12  mb-2 rounded"
                  data-bs-hover-animate="bounce"
                  data-aos="fade"
                  style={{
                    backgroundImage: "URL('" + item.image + "')",
                    width: "auto",
                    paddingTop: "25px",
                    paddingBottom: "60px",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-lg-6 col-sm-12">
                      <h3 class="text-white h2-custom">
                        {item.titre}
                        <br />
                      </h3>
                      <p
                        class="text-custom text-white text-justify produit-sm"
                        style={{
                          maxWidth: "560px",
                        }}
                      >
                        {item.description}
                        <br />
                      </p>
                    </div>
                  </div>
                  <div>
                    <NavLink
                      className="btn   d-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center bg-white text-primary font-weight-bold float-right"
                      type="button"
                      to={item.slug}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      En savoir plus
                    </NavLink>
                  </div>
                </div>
              )
            )
          )}
        </Grid>
      </Grid>
    </div>
  );
}
