import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Box from "@material-ui/core/Box";
import { NavLink } from "react-router-dom";

export default function Contact() {
  return (
    <>
      <Box bgcolor="#123463">
        <Grid
          className="py-4"
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={11} md={9}>
            <div className="fragment_7330">
              <div style={{ backgroundColor: "#123463" }}>
                <div className="container" style={{ padding: "20px" }}>
                  <div className="row">
                    <div className="col-md-8" data-aos="zoom-in-up">
                      <h2
                        className="text-break"
                        style={{ color: "rgb(255,255,255)" }}
                      >
                        Vous avez un projet <br/>et vous voulez en parler ?<br />
                      </h2>
                      <p
                        className="text-break text-justify text-blood"
                        style={{ color: "rgb(255,255,255)" }}
                      >
                        Contactez nous …
                      </p>
                    </div>
                    <div className="col-md-4 d-xl-flex justify-content-xl-center align-items-xl-center" data-aos="zoom-in"
                        data-aos-duration="3000">
                      <NavLink
                        className="btn  btn-lg d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center bg-white text-primary font-weight-bold"
                        
                        to="/contactus"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Contactez un expert
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
