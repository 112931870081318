import React from "react";
import Header from "../../components/produits/Header";
import Offre from "../../components/produits/Offre";
import PartenairesA from "../../components/produits/PartenairesA";
import Contact from "../../components/produits/Contact";
import UseCase from "../../components/produits/UseCase";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";

export default function Produit8() {
  const dataList = [
    {
      text: "ETIQUETTES",
      image:
        "/images/Produits/etiquette.webp",description: "Impression étiquettes"
    },
    {
      text: "RUBAN",
      image:
        "/images/Produits/rubbon.webp",description: "Impression ruban"
    },
    {
      text: "CARTE PVC",
      image:
        "/images/Produits/carte-pvc.webp",description: "Impression carte pvc"
    },
    {
      text: "REÇU",
      image:
        "/images/Produits/recu.webp",description: "Impression reçu"
    },
    {
      text: "JET D'ENCRE",
      image:
        "/images/Produits/Jet-d-encre.webp",description: "JET D'ENCRE"
    },
    {
      text: "TETE D'IMPRESSION",
      image:
        "/images/Produits/Tete-d-impression.webp",description: "TETE D'IMPRESSION"
    },
  ]
   
  const dataPart = [
    { image: "/images/ProduitPage/img/1.webp" ,description: "ZEBRA"},
    { image: "/images/ProduitPage/img/2.webp" ,description: "HONEYWELL"},
    { image: "/images/Partenaires/HP_logo.webp" ,description: "HP"},
  ];

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos Produits d’identification et traçabilité | CONSOMMABLE POUR IMPRESSION</title>
        <meta name="description" content="Nos consommables d'impression garantissent une qualité d'impression élevée et une longue durabilité, tout en réduisant l'usure de l'imprimante. Cela permet de diminuer les étiquettes manquantes, les appels de soutien informatique et les défaillances prématurées des têtes d'impression." />
      </Helmet>
      <div style={{ marginTop: 65 }}>
        <Header
          Title="CONSOMMABLE POUR IMPRESSION"
          image="images/Produits/Head-produit.svg"
        ></Header>
        <Grid container alignItems="center" justifyContent="center" textAlign="justify">
          <Grid item xs={11} md={9}>
            <span wfd-id="1313" className="h5" class="text-custom">
            Les consommables d'impression ont été sélectionnés avec soin afin d'assurer une qualité d'impression élevée, une durabilité et des performances de lecture inégalées, tout en réduisant l'usure de l'imprimante.
              <br />
              Réduire le nombre d'étiquettes manquantes ou illisibles, les appels de soutien informatique et les défaillances prématurées des têtes d'impression. 
            </span>
          </Grid>
        </Grid>
        <Offre
          list={dataList}
          max={"col-md-6 col-lg-4 text-center cust_blogteaser"}
          title="Découvrir nos offres"
        ></Offre>



        <PartenairesA list={dataPart}></PartenairesA>
      </div>
    </>
  );
}
