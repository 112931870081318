import React , {useEffect} from 'react';     
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper'; 
import CardDisplay from '../../components/CardDisplay'  
import DistributionWidget from '../../components/solutions/DistributionWidget'
import ReferencesWidget from '../../components/solutions/ReferencesWidget';
import ProjetWidget from '../../components/solutions/ProjetWidget';
import { Helmet } from 'react-helmet';

export default function Distribution(props) { 
 
    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

      const imageList = ["images/Solutions/Distribution/24.webp",
                         "images/Solutions/Distribution/carrefour.webp",
                          "images/Solutions/Distribution/17.webp",
                           "images/Solutions/Distribution/31.webp", 
                           "images/Solutions/Distribution/28.webp"];


  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos solutions digitaux| Solutions pour la distribution et les points de vente</title>
        <meta name="description" content="Les équipements de mobilité sont indispensables dans les points de vente pour le contrôle des prix, le balisage des rayons et l'assistance aux clients. Ils doivent être solides, légers et ergonomiques. Des fonctionnalités avancées comme la lecture de codes-barres à la volée, le NFC et le PTT favorisent la collecte de données et la collaboration entre les équipes. La supervision et la configuration à distance réduisent les temps d'arrêt et améliorent la productivité." />
      </Helmet>
     <div style={{marginTop:65}}> 
    <CardDisplay title="Solutions pour la distribution et les points de vente"
                 image="images/Solutions/Distribution/Solutions-pour-la-distribution-et-les-points-de-venteedit.webp"
                 description ="<div> <p>Que se soit pour des besoins de contrôle des prix, le balisage des rayons, l'assistance aux clients en magasin, les solutions de mobilité sont composées d'équipements à la fois robustes, légers et confortables à l'utilisation.<br>La taille de l'écran, l'autonomie de la batterie sont des critères importants pour donner aux gestionnaires de rayons les outils dont ils ont besoin tout au long de la journée.</p><p>Des fonctionnalités avancées telles que le PUSH TO TALK (PTT), la lecture code à barres à la volée, ou la lecture NFC rendent l'équipement multifonctions pouvant permettre à la fois l'accès aux données de (Prix, Stock, Cartes Clients, SAV) qu'à la collaboration entre les équipes.</p><p>Enfin, la configuration et supervision des équipements à distance permettent de minimiser les temps d'immobilisation et améliorer la productivité des équipes.</p></div>"></CardDisplay>
     <Grid container spacing={2} alignItems="center" justifyContent="center" >  
     <Grid item xs={11} md={9} > 
     <DistributionWidget></DistributionWidget>
     </Grid>
     <Grid item xs={11} md={9} > 
     <ReferencesWidget list={imageList}></ReferencesWidget>
     </Grid>
     <Grid item xs={11} md={9}> 
     </Grid>
 
     </Grid>
 </div>

  </>
  );
}