import * as React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Modal,
  InputAdornment,
} from "@mui/material";
import useForm from "../../../hooks/useForm";
import { createAPIEndpoint, ENDPOINTS } from "../../../api/Index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const getFreshModel = () => ({
  logo: "",
  raisonSocial: "",
});

export default function AjoutPartenaire(props) {
  const [open, setOpen] = useState(false);
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(getFreshModel);
  const handleClose = () => {
    props.setOpen(false);
    setOpen(false);
    setValues({});
  };

  useEffect(() => {
    if (props.open) setOpen(true);
    else setOpen(false);
  }, [props.open]);

  const Ajouter = (e) => {
    e.preventDefault();
    if (validate()) {
      createAPIEndpoint(ENDPOINTS.partenaire)
        .post(values)
        .then((res) => {
          setOpen(false);
          props.setChanged(true)
        })
        .catch((err) => console.log(err));
    }
  };

  const validate = (e) => {
    let temp = {};
    temp.raisonSocial =
      values.raisonSocial != "" ? "" : "Ce champ est obligatoire";
    setErrors(temp);
    return Object.values(temp).every((x) => x == "");
  };

  return (
    <div>
      {console.log("values: ",props.values)}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Ajout d'un partenaire
          </Typography>
          <form noValidate onSubmit={Ajouter}>
            <TextField
              label="Logo"
              type="file"
              name="logo"
              id="logo"
              variant="outlined"
              value={props.values.logo}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i class="fa-solid fa-image"></i>
                  </InputAdornment>
                ),
              }}
              sx={{ width: "90%", m: 1 }}
            />
            <TextField
              fullWidth
              label="Raison Sociale"
              name="raisonSocial"
              variant="outlined"
              size="small"
              value={props.values.raisonSocial}
              onChange={handleInputChange}
              sx={{ width: "90%", m: 1 }}
              {...(errors.raisonSocial && {
                error: true,
                helperText: errors.raisonSocial,
              })}
            />
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={{ width: "90%", m: 1 }}
            >
              Ajouter
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
