import React from 'react'
import { Col, Container, Row } from 'reactstrap'

function InfoContact(props) {
  return (
    <section className="section py-4" id="contacts">
        <Container className='pb-3'>
          <Row>
            <Col lg={props.lg} className="pt-2">
              <div className="card text-center h-100">
                <div className="mx-auto avatar-xxl p-2">
                  <div className="justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather text-primary feather-phone fea icon-md"
                    >
                      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                    </svg>
                  </div>
                </div>
                <h5 className="text-uppercase text-primary">
                  Téléphone
                </h5>
                    <p className="mb-0">(+212) 0522 307 664</p>
                <p className="mb-0">(+212) 0522 307 664</p>
                
              </div>
            </Col>
            <Col lg={props.lg} className="pt-2">
              <div className="card text-center h-100">
                <div className="mx-auto avatar-xxl p-2">
                  <div className="justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather text-primary feather-mail fea icon-md"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                  </div>
                </div>
                <h5 className="mb-3 text-primary text-uppercase">
                  Email
                </h5>
                    <a href='mailto:(contact@teledyne.ma)' className="email mb-4">
                  contact@teledyne.ma
                </a>
                
              </div>
            </Col>
            <Col lg={props.lg} className="pt-2">
              <div className="card text-center  h-100">
                <div className="mx-auto avatar-xxl p-2">
                  <div className="justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather text-primary feather-map-pin fea icon-md"
                    >
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                      <circle cx="12" cy="10" r="3"></circle>
                    </svg>
                  </div>
                </div>
                <h5 className="text-primary text-uppercase">
                  Adresse
                </h5>
                <p className="mb-0">
                  2, rue Abdelouhab Azzakkak, Roches Noires
                </p>
                <p className="mb-0" >Casablanca 20310, Maroc</p>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
  )
}

export default InfoContact