import React, { useEffect, useState } from "react";
import Header from "../../components/produits/Header";
import Offre from "../../components/produits/Offre";
import PartenairesW from "../../components/produits/PartenairesW";
import Contact from "../../components/produits/Contact";
import Grid from "@mui/material/Grid";
import useStateContext from "../../hooks/useStateContext";
import { createAPIEndpoint, ENDPOINTS } from "../../api/Index";
import { useLocation } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";

export default function DescriptionProduits() {
  const { context, setContext } = useStateContext();
  const location = useLocation();
  const [produit, setProduit] = useState(null);
  const [dProduit, SetDProduit] = useState([
    {
      Title: "VISUAL STOCK",
      ShortTitle: "Solution de pilotage opérationnel de l'inventaire physique",
      Description:
        "Visual Stock est une solution qui vise la supervision de l’inventaire physique, son objectif est de le piloter d’unemanière plus intelligente et plus rapide avec une vision plus claire sur le déroulement des comptages. La solution est présente « On premise » et éventuellement elle sera « On Cloud ». Elle assure des résultats exhaustifs et précis dans un temps court, elle est aussi adaptée à tous les types d’inventaire et aide à l'identification automatique des anomalies. La solution dispose des pistes d’audit adapté à des différents états ainsi qu’il assure l’intégration directe des résultats d’inventaire dans vos systèmes.",
      SecondDescription:
        "Cette solution vise les entreprises exploitant plusieurs magasin et/ou entrepôts de stockage, et cherchant à identifier un propre référentiel article pour chacune de ses différentes entités. Cette solution est adapté aux différentes types d’entreprise dont les produits en stock sont étiquetés avec code à barres (éventuellement RFID) et disposant ou non d’un logiciel de gestion de stock telle que les ERP, les WMS… \nVisual Stock facilite aussi l’intégration des prestataires externes pour réaliser votre inventaire. Il prendre en compte les bons pratique de l’inventaire, non seulement de corriger le stock mais en le faisant avec une méthode particulier afin d’auditer de stock et superviser la cohérence avec un respect de la méthodologie de l’entreprise.",
      Image: "images/Produits/Vs.webp",
      ImageLogo: "/images/Produits/LogoVS1.webp",
      avantages: [
        {
          avantage:
            "Préparation et Organisation par un zoning, une affectation et une supervision des opérations de comptage.",
        },
        { avantage: "Récupération en temps réel des résultats de comptage." },
        {
          avantage:
            "Impression des étiquettes pour les Zones de comptage, articles et conditionnements.",
        },
        {
          avantage:
            "Contrôle automatique des données relevées et des quantités comptées.",
        },
        {
          avantage:
            "Calcul d’indicateurs de performance : état d’avancement, rapidité d’exécution, fiabilité des données…",
        },
        {
          avantage:
            "Audit et détection automatique des anomalies : référence, code à barres, quantité, série, lots…",
        },
        {
          avantage:
            "Consolidation des stocks, et traitement de spécificités: Textile, Composants, Variants…",
        },
        {
          avantage:
            "Export des données d’inventaire et édition de rapports de gestion.",
        },
      ],
    },
    {
      Title: "SOTI",
      ShortTitle:
        "Simplification de la mobilité des entreprises et les solutions IoT",
      Description:
        "La plate-forme SOTI ONE vous offre un contrôle et une visibilité complets sur tous vos appareils mobiles sur le terrain : smartphones, tablettes, ordinateurs, appareils robustes, imprimantes industrielles, etc. C'est une solution de gestion de la mobilité d'entreprise pour gérer tous vos besoins de mobilité critiques : développement d'applications, gestion et sécurisation des appareils, dépannage et résolution à distance des problèmes d'appareils mobiles, obtention d'informations analytiques sur les performances des appareils et gestion de vos déploiements IoT.",
      SecondDescription:
        "La plateforme SOTI ONE se compose des applications suivantes : \nSOTI MobiControl est une solution de gestion de la mobilité d'entreprise (EMM) qui permet aux entreprises de gérer plus facilement les appareils en toute sécurité. \nSOTI Assist est une solution de gestion des incidents du service d'assistance qui facilite l'identification et le diagnostic des problèmes dans votre déploiement EMM. \nSOTI Snap est une solution de développement d'applications mobiles multiplateforme qui permet aux utilisateurs de créer des applications mobiles rapidement et à moindre coût, sans avoir besoin de ressources spécialisées. \nSOTI Connect est une solution de gestion IoT conçue pour les entreprises qui fournit une gestion complète du cycle de vie des appareils IoT au sein d'une organisation. \nSOTI Insight est une solution d'informatique décisionnelle qui fournit des analyses prêtes à l'emploi sur les applications, les appareils et le comportement des employés. \nSOTI Central est une communauté en ligne permettant aux partenaires et clients SOTI d'obtenir des réponses d'experts produits, d'interagir avec la communauté SOTI et de parcourir une bibliothèque de produits et services construits autour de la plate-forme SOTI ONE.",
      Image: "/images/Produits/sotiUtil.webp",
      ImageLogo: "/images/Produits/SOTI.webp",
      avantages: [
        {
          avantage:
            "Simplifier la mobilité professionnelle de votre entreprise : La plateforme SOTI ONE est une suite intégrée de solutions de mobilité professionnelle pour votre entreprise. Elle permet à vos employés de travailler, de créer vos applications plus rapidement et de gérer vos appareils mobiles et vos terminaux IdO (IoT)",
        },
        {
          avantage:
            "Minimiser les temps d'arrêt : Elle contient des outils de support et de gestion nécessaires pour résoudre plus rapidement les problèmes des appareils mobiles et des informations analytiques afin de vous aider à prendre des décisions plus intelligentes, basées sur des données, autour de votre stratégie mobile essentielle et critique pour l'entreprise.",
        },
        {
          avantage:
            "Gestion de la mobilité de l'entreprise : Elle sert à faciliter la gestion de la mobilité. Son objectif est de simplifier la sécurité et la gestion de votre mobilité stratégique de votre entreprise et du déploiement d'Ido (IoT)",
        },
        {
          avantage:
            "Développement rapide d'applications: C'est une solution de développement d'applications, elle est rapide et multiplateforme destiné aux entreprises. Elle permet aux organisations de créer les applications mobiles dont elles ont besoin plus rapidement, à moindre coût et en respectant les délais.",
        },
      ],
    },
    {
      Title: "BARTENDER",
      ShortTitle:
        "Création et impression d’étiquettes avec ou sans code-barres",
      Description:
        "Bartender est un système d’étiquetage professionnel éprouvé qui simplifie votre chaîne logistique et votre étiquetage de conformité, au lieu d’un système compliqué générant des coûts récurrents. Le modèle BarTender est conçu pour prendre en charge la complexité des déploiements à l’échelle de l’entreprise. C’est le logiciel d’étiquetage de référence pour les chaînes logistiques et les processus de fabrication les plus dynamiques au monde, dans tous les secteurs : de l’aérospatiale et de l’automobile à la chimie et à la pharmacie, en passant par les aliments et les boissons et le commerce de détail et les dispositifs médicaux. BarTender garantit la sécurité, la traçabilité, la transparence et l’interopérabilité qui vous permettent d’être en conformité avec la règlementation et d’améliorer la vitesse de votre chaîne logistique.",
      SecondDescription:
        "L'objectif du BarTender est de donner à votre entreprise un puissant avantage concurrentiel. Il sert à la gestion des déploiements d’étiquetage d’entreprise comprenant des intégrations ERP, un schéma de gestion des données complexe, des installations gérées de manière centralisée couvrant des milliers de sites mondiaux - dans des environnements hautement réglementés, dans tous les secteurs d’activité et dans toutes les régions géographiques. \nBarTender Seagull aide les sociétés de toutes tailles à respecter les réglementations auxquelles elles sont soumises en matière d’étiquetage. Ce logiciel intègre 400 modèles de codes-barres basés sur 95 symbologies",
      Image: "/images/Produits/Foto-BarTender.webp",
      ImageLogo: "/images/Produits/bartender.webp",
      avantages: [
        {
          avantage:
            "La prise en charge native de XML permet de simplifier l’intégration de BarTender aux systèmes que vous utilisez quotidiennement.",
        },
        {
          avantage:
            "Intégration complète des capacités d’impression de BarTender avec la quasi-totalité des systèmes WMS ou ERP",
        },
        {
          avantage:
            "Planification des plusieurs actions dans un même événement déclencheur.",
        },
        {
          avantage:
            "Gestion de manière transparente le basculement et les redirections d’imprimantes pour que votre étiquetage reste en production.",
        },
        {
          avantage:
            "La possibilité de rechercher et sélectionner facilement des enregistrements et intégrer les données d’impression.",
        },
        { avantage: "Conservation de l’apparence de votre système actuel." },
        {
          avantage:
            "La prise en charge native de XML sert à se connecter à votre système d’étiquetage à vos données sans aucun API. Même la maintenance standard et le support sont gratuits pour la première année.",
        },
      ],
    },
    {
      Title: "CODESOFT",
      ShortTitle: "Logiciel d'étiquetage codes barres",
      Description:
        "Le logiciel Codesoft est une application d’étiquetage dédiée aux entreprises. Avec sa puissance et sa flexiblité c’est un assistant hors pair pour la réalisation de vos travaux de conception et d’édition d’étiquettes. Il est disponible en configuration impression seule, monoposte ou réseau. Que votre enjeu soit de gérer des biens et des ressources, de contrôler des niveaux de stocks, de suivre ou de gérer des données , Codesoft est conçu pour répondre à l’étiquetage complexe dans votre entreprise. La licence par abonnement disponible pour Codesoft , son coût initial est plus faible et apporte plus de valeur que jamais.",
      SecondDescription:
        "Ce logiciel excelle surtout en termes de contrôle afin d’intégrer un système automatisé relatif à un serveur. De ce fait, il permet de suivre les flux de distribution et de production, de consulter l’état du stock à tout moment. Son utilisation rime également avec la traçabilité des produits assurée. \nCodesoft permet un enregistrement et un accès avec une meilleure technique de gestion des bases de données du plus simple au plus complexes. Il permet d’accéder à un système de contrôle perfectionné et performant avec la technologie RFID. \nCe logiciel multifonction ne se contente pas alors d’imprimer vos codes-barres, mais vous aide aussi dans la gestion de vos actifs et de vos ressources de manière optimisée. Ainsi, non seulement cet outil fait gagner du temps et d’énergie pour accroître le temps de productivité, mais il offre aussi une meilleure assistance en matière de gestion et d’organisation.",
      Image: "/images/Produits/codesoft-use.webp",
      ImageLogo: "/images/Produits/codesoft.webp",
      avantages: [
        {
          avantage:
            "Réalisation d’étiquettes complexes: Avec ses fonctions avancées le logiciel Codesoft est une application performante de création d’étiquettes. Il offre l’avantage de concevoir facilement une étiquette, de la relier à une base de données, ou bien d’ajouter des codes-barres complexes comme le HIBC ou GS1 Databar en utilisant son interface intuitive.",
        },
        {
          avantage:
            "Souplesse et efficacité: En plus de la création d’étiquettes classiques, Codesoft dispose de fonctions intégrant facilement l’impression d’étiquettes de technologie RFID destinés à l’amélioration de l’efficacité et du contrôle de vos processus métiers. Son interface intelligente, ses assistants et les connexions aisées aux bases de données offre une grande souplesse à l’intégration de Codesoft à votre processus d’étiquetage.",
        },
        {
          avantage:
            "Un assistant productivité: Les besoins d’impressions rapides, de contrôle et d’automatisation  sont grandissants. Codesoft répond à ces besoins de productivité lorsqu’il est associé aux solutions entreprise comme Sentinel pour l’impression automatisée, label archive pour la sécurité et la traçabilité des étiquettes et Teklynx Central pour la gestion centralisée des étiquettes.",
        },
      ],
    },
    {
      Title: "WMS - Savoye maGistor",
      ShortTitle: "Pilotage des processus logistique",
      Description:
        "MaGistor est un WMS existant en SaaS ou en version sur site et adapté aux plateformes logistiques complexes, manuelles ou hautement automatisées. Il vous permet d'organiser l'ensemble de vos activités logistiques sur votre site en gérant à la fois l'entrepôt de stockage et les activités de préparation de commandes. Vous disposez d'un outil simple et unique pour piloter votre entrepôt, grâce à l'automatisation des mouvements de marchandises de base et au suivi d'activité en temps réel. MaGistor est interfaçable avec tous les équipements techniques d'entrepôt, depuis les bornes radiofréquence jusqu'aux étiqueteuses.",
      SecondDescription:
        "MaGistor est une solution complète, adaptée aux petites et moyennes entreprises telles que les 3PL, les transporteurs, les distributeurs spécialisés, les fournisseurs industriels, l'industrie alimentaire, les soins de santé, le commerce de détail…\nCette solution permet une configuration rapide et facile et convient aux entreprises multisites et multi-entreprises.",
      Image: "/images/Produits/WMSUse.webp",
      ImageLogo: "/images/Produits/wms.webp",
      avantages: [
        {
          avantage:
            "Maîtrisez votre entrepôt de stockage : La solution facilite le suivi de l'état d'avancement des marchandises, depuis leur arrivée jusqu'à leur stockage. Elle intègre la gestion complète des emplacements de stockage, en tenant compte de leurs différentes caractéristiques, et assure la visibilité des marchandises grâce à toutes les fonctionnalités possibles. Elle gère les emplacements de réserve ainsi que les emplacements de picking, et cherche constamment à optimiser les mouvements de réapprovisionnement.",
        },
        {
          avantage:
            "Optimisation de la préparation de commande : MaGistor prend en charge tous les types de préparation, que ce soit en complétant une palette, en remplissant une caisse entière ou en fractionnant la préparation en caisses individuelles. Quel que soit le type de commande client, il vous permet d'utiliser le moyen de préparation le mieux adapté en tenant compte du matériel disponible.",
        },
        {
          avantage:
            "Intuitif, clair, convivial et facile à utiliser : Il a été conçu pour vous offrir un affichage clair et une navigation flexible. Que vous soyez à un poste de travail ou à l'aide d'un terminal mobile, vous disposez de toutes les informations dont vous avez besoin. L'outil est très facile à utiliser.",
        },
      ],
    },
  ]);
  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.descriptionProduit)
      .fetchByName(location.state)
      .then((res) => {
        setProduit(res.data)
      })
      .catch((err) => console.log(err));
    window.scrollTo(0, 0);
  }, []);
  const avantage = produit != null && produit.avantages.map((note) => (
    <p class="text-white" style={{ whiteSpace: "pre-line" }}>
      <i class="fa-solid fa-genderless"></i> {note.avantage}
    </p>
  ));
  const myStyle = {
    backgroundImage: "url('/images/Produits/Side_product.svg')",
    height: "100%",
    marginTop: "20px",
    backgroundSize: "cover",
    backgroundRepeat: "repeat",
  };
  return (
    produit == null ? (
      <Box
        sx={{
          display: "flex",
          height: 300,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={100} thickness={5} />
      </Box>
    ) : (
      <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>{produit.shortTitle}</title>
        <meta
          name="description"
          content={produit.description}
        />
      </Helmet>
        <div style={{ marginTop: 65 }}>
          <div className="mx-auto" style={{width:"80%"}}>
            <div class="row p-2">
              <div class="col-lg-8 col-md-7 col-xs-12">
                <h3 class="text-primary pt-4" data-aos="fade-up">
                  {produit != null && produit.shortTitle}
                </h3>
                <div
                  className="text-justify py-4"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {produit != null && produit.description}
                </div>
                <img src={produit != null && produit.image} class="card-img w-100 px-2 rounded" alt="..." />
                <div
                  className="text-justify py-4 text-break"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {produit != null && produit.secondDescription}
                </div>
              </div>
              <div class="col-lg-4 col-md-5 col-sm-12 rounded py-4 px-4 " style={myStyle}>
                <h4 class="text-center py-2 text-warning">
                  AVANTAGES
                </h4>
                <div className="pl-2 text-justify">{avantage}</div>
                <div className="p-2 text-center">
                  <img
                    src={produit != null && produit.imageLogo}
                    class="img-fluid w-50 rounded"
                    alt="Visual stock logo"
                  />
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </>)
  );
}
