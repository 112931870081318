import React from 'react';
import Header from '../../components/produits/Header';
import Offre from '../../components/produits/Offre';
import PartenairesW from '../../components/produits/PartenairesW';
import Contact from '../../components/produits/Contact';
import Grid from '@mui/material/Grid';
import UseCase from '../../components/produits/UseCase';
import { Helmet } from 'react-helmet';

export default function Produit1() {

  const dataList = [
    { text: 'Imprimantes mobiles', image: 'images/ProduitPage/img/imprimantes-mobiles.webp', description: "Imprimantes mobiles RP4e" },
    { text: 'Imprimantes industrielles', image: 'images/ProduitPage/img/imprimantes-industrielles.webp', description: "Imprimantes industrielles PX940" },
    { text: 'Imprimantes de bureau', image: 'images/ProduitPage/img/imprimantes-de-bureau.webp', description: "Imprimantes de bureau ZD620" },
    { text: 'Imprimantes RFID', image: 'images/ProduitPage/img/imprimantes-rfid-zebra.webp', description: "Imprimantes RFID ZT610" },
    { text: 'Imprimantes pour carte d\'identification', image: '/images/Produits/zc100.webp', description: "Imprimantes PVC ZC100" },
    // { text: 'Consommables', image: 'images/ProduitPage/img/consommables.webp' }, 
    // { text: 'Logiciels de création et impression d\'étiquettes', image: 'images/ProduitPage/img/code-barre-logiciels.webp' },
  ]

  const dataPart = [
    { image: 'images/ProduitPage/img/1.webp', description: "ZEBRA" },
    { image: 'images/ProduitPage/img/2.webp', description: "HONEYWELL" },
    { image: 'images/ProduitPage/img/3.webp', description: "TEKLYNX" },
    { image: 'images/ProduitPage/img/4.webp', description: "DASCOM" },
    { image: 'images/ProduitPage/img/5.webp', description: "PRINTRONIX" },
    { image: 'images/ProduitPage/img/6.webp', description: "BARTENDER" },
  ]
  const dataCase = [
    { image: '/images/Produits/Impr-1.webp', description: "Imprimantes entrepot" },
    { image: '/images/Produits/Impr-2.webp', description: "Imprimantes santé" },
    { image: '/images/Produits/Impr-3.webp', description: "Imprimantes étiquettes" },
  ]
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos Produits d’identification et traçabilité | IMPRIMANTE D'ÉTIQUETTES</title>
        <meta name="description" content="Nos imprimantes et accessoires répondent à tous vos besoins d'impression, que ce soit une imprimante industrielle, RFID, de bureau ou mobile. Elles améliorent la précision et l'efficacité, offrent une mobilité sans compromettre la qualité ou la fiabilité d'impression." />
      </Helmet>
      <div style={{ marginTop: 65 }}>
        <Header Title="IMPRIMANTE D'ÉTIQUETTES" image="images/Produits/Head-produit.svg"></Header>
        <Grid container alignItems="center" justifyContent="center" textAlign="justify" >
          <Grid item xs={11} md={9} >
            <div className='text-custom'>Offrez-vous un niveau supérieur de flexibilité et de performance. Que vous recherchiez une imprimante industrielle, RFID, de bureau ou mobile, notre gamme complète d'imprimantes et d'accessoires répond à tous vos besoins en matière d’impression.
              Conçues pour améliorer la précision et l'efficacité et la productivité, nos imprimantes riches en fonctionnalités offrent une mobilité ininterrompue sans sacrifier la qualité ou la fiabilité d'impression. </div>
          </Grid>
        </Grid>
        <Offre list={dataList} max={"col-md-6 col-lg-4 text-center cust_blogteaser"} title="Découvrir nos offres"></Offre>
        <UseCase
          list={dataCase}
          Txt="Ces imprimantes peuvent être utiliser dans des différents services tel que la santé, la distribution, la fabrication ainsi que la gestion des entrepôts."
        ></UseCase>
        <PartenairesW list={dataPart}></PartenairesW>
      </div>
    </>
  );
}