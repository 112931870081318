import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardDisplay from '../../components/CardDisplay'
import DistributionWidget from '../../components/solutions/DistributionWidget'
import ReferencesWidget from '../../components/solutions/ReferencesWidget';
import ProjetWidget from '../../components/solutions/ProjetWidget';
import OwlCarouselX4 from '../../components/OwlCarouselX4'
import { Helmet } from 'react-helmet';




export default function Industrie(props) {

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const imageList = ["images/Solutions/Industrie/69.webp",
    "images/Solutions/Industrie/50.webp",
    "images/Solutions/Industrie/Marsa-Maroc.webp",
    "images/Solutions/Industrie/58.webp"];

  const dataList = [
    { lien: "#test", promo: false, image: 'images/Solutions/Industrie/terminal-embarque-vc8300-zebra.webp', title: 'Terminal embarqué HONEYWELL VM1', description: 'Pour les engins de manutention, chariots mobiles et véhicules' },
    { lien: "#test", promo: false, image: 'images/Solutions/Industrie/ck75-honeywell-terminaux-pda.webp', title: 'Terminal Honeywell CK75', description: 'Des terminaux Robustes pour les opérateurs en entrepôt' },
    { lien: "#test", promo: false, image: 'images/Solutions/Industrie/terminaux-mobiles-pda-zebramc93.webp', title: 'Terminal ZEBRA MC93xx', description: 'Gamme de terminaux Ultra Robustes avec choix de lecteurs Code à barres courte à très longue distance' },
    { lien: "#test", promo: false, image: 'images/Solutions/Industrie/DS3608-HPDS3678-HP-1D2D-ULTRA-RUGGED-SCANNER.webp', title: 'Scanners Code à barres ZEBRA DS36xx', description: 'Gamme de Scanners Industriels avec capacité de lecture ultra perfectionée' },
    { lien: "#test", promo: false, image: 'images/Solutions/Industrie/zt230-zebra-imprimante-industrie.webp', title: 'Imprimantes ZEBRA ZT4xx', description: "Gamme d'imprimantes d'étiquettes industrielles" },
    { lien: "#test", promo: false, image: 'images/Solutions/Industrie/CODESOFT-logo.webp', title: 'Logiciel CODESOFT', description: "Logiciel d'automatisation des impressions d'étiquettes code à barres en liaison avec les ERP" },
    { lien: "#test", promo: false, image: 'images/Solutions/Industrie/Tablette-ZEBRA-ET56.webp', title: 'Tablette ZEBRA ET56', description: "La tablette robuste, multifonctions pour l'accès à distance aux ERP et applications métier" }
  ]


  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos solutions digitaux| Solutions digitales pour l'industrie</title>
        <meta
          name="description"
          content="TELEDYNE offre des moyens techniques adaptés aux environnements et aux utilisations industrielles, allant de l'identification des produits à la collecte de données. Nos imprimantes d'étiquettes haut de gamme et terminaux robustes sont conçus pour fonctionner dans des environnements rigoureux. TELEDYNE assure également l'intégration de ces équipements aux environnements informatiques de l'entreprise"
        />
      </Helmet>
      <div style={{ marginTop: 65 }}>
        <CardDisplay title="Solutions digitales pour l'industrie"
          image="/images/Solutions/Industrie/Solutions-digitales-pour-l-industrieedit.webp"
          description="<div> <p>En milieu industriel, il est reconnu que l'environnement de travail, n'est pas toujours tendre ni avec les Hommes, ni avec les équipements. Les équipements doivent être choisis pour supporter la poussière, la chaleur, les changements brusques de température, les gans des opérateurs, parfois la pluie ou l'ensoleillement excessif.</p><p>Chez TELEDYNE, nous proposons aux industriels les moyens techniques adaptés à leur environnement et leur utilisation.</p><p>Les besoins de l'industrie vont de l'identification des produits ou des caisses et palettes, à la collecte des données à travers des terminaux robustes et pouvant lire des codes à barres à courte ou longue distance.</p><p>Nos imprimantes d'étiquettes Haut de gamme permettent d'imprimer en batch des milliers d'étiquettes par heure. Nos terminaux sont conçus pour un fonctionnement dans les environnements les plus rigoureux. Enfin, nous assurons également l'intégration de ces équipements aux environnements IT de l'entreprise. Il peut s'agir d'automates industriels, d'ERP ou de solutions logicielles verticales (GPAO, TMS, ... etc.)</p></div>"></CardDisplay>
        <Grid container spacing={2} alignItems="center" justifyContent="center" >
        <Grid item xs={11} md={9}> 
            <OwlCarouselX4 h1="Produits phares et services proposés en milieu industriel" list={dataList}></OwlCarouselX4>
          </Grid>
          <Grid item xs={11} md={9}> 
            <ReferencesWidget list={imageList}></ReferencesWidget>
          </Grid>
          <Grid item xs={11} md={9}> 
          </Grid>


        </Grid>
      </div>

    </>
  );
}