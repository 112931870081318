import React   from 'react';   
import Header from '../../components/produits/Header'; 
import Offre from '../../components/produits/Offre'; 
import PartenairesA from '../../components/produits/PartenairesA'; 
import Contact from '../../components/produits/Contact'; 
import UseCase from '../../components/produits/UseCase';  
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';

 export default function Produit1() { 
    
    const dataList = [
        { text: 'ZEBRA ET56 8 ou 10 pouces', image: 'images/ProduitPage/img/TABLETTE-PROFESSIONNELLE-WINDOWS-ET51ET56.webp' ,description: "ZEBRA ET56"},    
        { text: 'ZEBRA Xpad L10', image: 'images/ProduitPage/img/ZEBRA-Xpad-L10.webp' ,description: "ZEBRA Xpad L10"},    
        { text: 'SAMSUNG TAB ACTIVE 3', image: 'images/ProduitPage/img/fr-galaxy-tab-active3-enterprise-edition-sm-t575nzkaeeh-frontblack-317389293.webp' ,description: "SAMSUNG TAB ACTIVE 3"},    
        { text: 'SAMSUNG TAB ACTIVE PRO 10 pouces', image: 'images/ProduitPage/img/SM-T547_001_Front_Black_600x600.webp' ,description: "SAMSUNG TAB ACTIVE PRO"},   
      ] 

    const dataPart = [
      {  image: '/images/ProduitPage/img/1.webp' ,description: "ZEBRA"},       
      {  image: '/images/ProduitPage/img/2.webp' ,description: "HONEYWELL"},    
      {  image: 'images/ProduitPage/img/7.webp' ,description: "SAMSUNG"},         
  ] 

  const dataCase = [
    {  image: 'images/ProduitPage/img/field-mobility-photography-website-blog-telco-workers-tablet.webp' ,description: "Tablette industruel production"},       
    {  image: 'images/ProduitPage/img/et55-photography-website-blog-delivery-driver-in-truck-edited-for-et56-launch-1280x720.webp' ,description: "Tablette industruel transport"},        
    {  image: 'images/ProduitPage/img/t-and-l-photography-website-blog-delivery-man-inside-truck-with-tablet-1280x720.webp' ,description: "Tablette industruel distribution"},       
    {  image: 'images/ProduitPage/img/police-photography-website-blog-pointing-at-tablet-1280x720.webp' ,description: "Tablette industruel service public"},   
] 
  return (
      <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nos Produits d’identification et traçabilité | TABLETTES INDUSTRIELLES</title>
        <meta name="description" content="Nos tablettes industrielles répondent à des exigences élevées en matière de robustesse sur le terrain. Disponibles sous Android ou Windows, elles offrent une gamme de fonctionnalités permettant d'exécuter des applications métier, de collecter des données et de gérer les communications en toute sécurité." />
      </Helmet>
     <div style={{marginTop:65}}>
	   <Header Title="TABLETTES INDUSTRIELLES" image="images/Produits/Head-produit.svg"></Header>
     <Grid container   alignItems="center" justifyContent="center"  >
          <Grid item xs={11} md={9} >

          <span wfd-id="1313" class="text-custom">Sur le terrain, les tablettes exigent également un niveau élevé de robustesse. &nbsp;<br/>Notre gamme de tablettes industrielles couvre des besoins divers et variés. Sous Android ou bien Windows, elles permettent d’exécuter vos applications métiers, relever des données et gérer les communications de manière sûre.&nbsp;</span>
              </Grid>
   </Grid>
    <Offre list={dataList} max={"col-md-6 text-center cust_blogteaser"} title="Découvrir nos offres"></Offre>


    <UseCase Txt="Les tablettes industrielles ont des utilisation divers dans des différents services tel que la santé, la distribution, la fabrication ainsi que la gestion des entrepôts." list={dataCase}></UseCase>



     <PartenairesA list={dataPart}></PartenairesA>
     </div>
    </>
  );
}