import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { createAPIEndpoint, ENDPOINTS } from "../api/Index";
import { Box, CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

export default function Services() {
  const [service, setService] = useState([]);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GetServices)
      .fetch()
      .then((res) => {
        setService(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Nos services | Installation, configuration, maintenance et
          infrastructure
        </title>
        <meta
          name="description"
          content="Teledyne vous propose une variété des services en installation et configuration, en maintenance et SAV, en développement d’applications, et en monitoring d’infrastructure."
        />
      </Helmet>
      <div className="blue-container">
        <div className="row">
          <div className="col-md-12">
            <div id="Titre" type="text">
              {" "}
              <h1 className="text-center text-warning pulse animated title-custom">
                Nos services
              </h1>
            </div>
            <div id="Description" type="text">
              {" "}
              <p className="text-center text-custom text-white pulse animated">
                Nous vous proposons un ensemble de services de proximité, un
                support et une expertise locale, mis en place et perfectionnés
                au fil des projets réalisés depuis 1995.<br/>Si vous souhaitez en
                savoir plus sur nos services ou discuter de vos projets avec
                nous, vous pouvez visiter notre page de{" "}
                <NavLink
                    exact
                    to="/contactus"
                    activeClassName="active"
                    className="text-warning"
                  >
                    <strong>
                     contact <i class="fa fa-external-link" aria-hidden="true"></i>
                  </strong>
                  </NavLink>.<br/>Nous sommes impatients de vous aider à réaliser vos projets.
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
      <Grid
        className="my-2"
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={11} md={10}>
          <div
            class=" mb-lg-0 ml-lg-0 mr-lg-0 mt-lg-0 pb-lg-0 pl-lg-0 pr-lg-0 pt-lg-0"
            style={{
              boxSizing: "border-box",
              borderStyle: "solid",
              borderWidth: "0px",
              opacity: "1.0",
            }}
          >
            <div id="fragment-6777-qypx">
              {" "}
              <div class="pagesolutions">
                {service.length == 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: 300,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={100} thickness={5} />
                  </Box>
                ) : (
                  <div class="row text-left m-auto">
                    {service.map(
                      (item) =>
                        item.type == 2 && (
                          <div
                            class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex d-xl-flex align-self-center"
                            style={{ minHeight: "400px" }}
                          >
                            <div
                              style={{ paddingBottom: "10px", margin: "10px" }}
                              data-aos="fade-up"
                              class="aos-init"
                            >
                              <div
                                class="container text-primary justify-content-center align-items-center px-0"
                                data-bs-hover-animate="pulse"
                                style={{
                                  marginBottom: "15px",
                                  paddingTop: "10px",
                                }}
                              >
                                <img
                                  class="d-flex d-lg-flex flex-row flex-shrink-1 justify-content-center align-items-center align-content-center align-self-center m-auto justify-content-lg-center bounce animated"
                                  src={item.image}
                                  loading="lazy"
                                  style={{
                                    width: "120px",
                                    minWidth: "auto",
                                    paddingBottom: "10px",
                                  }}
                                />

                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                  <h2
                                    class="text-left text-primary h2-custom"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    {item.titre}
                                    <br />
                                  </h2>
                                  <p
                                    class="text-justify"
                                    style={{ paddingTop: "15px" }}
                                  >
                                    {item.description}
                                    <br /> <br />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
