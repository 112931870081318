import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardDisplay from '../../components/CardDisplay'
import ReferencesWidget from '../../components/solutions/ReferencesWidget';
import OwlCarouselX4 from '../../components/OwlCarouselX4';
import { Helmet } from 'react-helmet';


export default function Banque(props) {

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  const DisplayOptions = {
    title: "Solutions pour les banques et assurance",
    image: "images/Solutions/Banque/banque-services-terciaire-assuranceedit.webp",
    description: "<div> <p>Le secteur tertiaire conduit sa révolution digitale par l'intégration de l'agence virtuelle et de la mobilité comme moyen le plus répandu d'interaction avec la clientèle. La banque est entrain de muter vers l'agence digitale. La diffusion de l'information est désormais véhiculée à travers des tablettes ou des écrans interactifs. Le portail WIFI permet d'accueillir le client et le guider pour une expérience client plus riche et intuitive.</p><p>Dans le milieu de l'assurance ou de la mutuelle, le digital remplace désormais les guichets d'accueil clientèle, et l'intéraction avec le client est également entrain de muter vers des plateformes digitales intelligentes plus pertinentes.</p><p>TELEDYNE apporte son expertise dans la mise en place de solutions mobiles pour le compte du secteur tertiaire. Il s'agit d'équiper les agences et espaces d'accueil de solutions WIFI intelligentes avec Hot Spot et intégration des canaux de diffusion d'informations digitaux. Il s'agit également de fournir des tablettes pour les collaborateurs, devenus désormais itinérants ou travailleurs à distance ou des écrans tactiles interactifs en libre service pour leurs clients. Enfin, TELEDYNE propose également des solution EMM/MDM pour sécuriser et superviser ces équipements mobiles, ainsi que pour contrôler la diffusion programmée des documents et médias aux collaborateurs.</p></div>",
  };


  const imageList = ["images/Solutions/Entrepot/20.webp",
    "images/Solutions/Entrepot/Untitled-2.webp",
    "images/Solutions/Entrepot/cnss.webp"
  ];

  const dataList = [
    { lien: "#test", promo: false, image: 'images/Solutions/Entrepot/Tablette_ZEBRA_ET56.webp', title: 'Tablette ZEBRA ET56', description: "La tablette robuste, multifonctions pour l'accès à distance aux plateformes digitales et la collecte itinérante de données" },
    { lien: "#test", promo: false, image: '/images/Solutions/ServicesPubliques/Tablette_Samsung_Active_3_Pro.webp', title: 'Tablette Samsung Active 3 Pro', description: "Devenue l'une des meilleurs tablettes en agence bancaire" },
    { lien: "#test", promo: false, image: 'images/Solutions/Entrepot/infrastructure-wifi-points-d-acces.webp', title: 'Installation WIFI', description: "Solution WIFI et Hot Spot avec supervision distribuée et monitoring à distance" },
    { lien: "#test", promo: false, image: 'images/Solutions/Entrepot/Plateforme-SOTI-ONE.webp', title: 'Plateforme SOTI ONE', description: "Logiciel EMM/MDM de configuration et supervision à distance de terminaux mobiles" }
  ]


  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos solutions digitaux| Solutions pour les banques et assurance</title>
        <meta name="description" content="Le secteur tertiaire est en pleine révolution digitale avec l'intégration de l'agence virtuelle et la mobilité pour interagir avec les clients. Dans le milieu de l'assurance ou de la banque, le digital remplace les guichets d'accueil et l'interaction client est en train de muter vers des plateformes digitales intelligentes. TELEDYNE offre des solutions mobiles telles que des Hot Spots WIFI, des tablettes pour les collaborateurs, des écrans interactifs et des solutions de gestion et de sécurité pour ces équipements mobiles." />
      </Helmet>
      <div style={{marginTop:65}}>
        <CardDisplay  {...DisplayOptions}></CardDisplay>
        <Grid container spacing={2} alignItems="center" justifyContent="center" >
          <Grid item xs={11} md={9}>
            <OwlCarouselX4 h1="Produits phares et services proposés dans le secteur tertiaire" list={dataList}></OwlCarouselX4>
          </Grid>
          <Grid item xs={11} md={9}  >
            <ReferencesWidget list={imageList}></ReferencesWidget>
          </Grid>
          <Grid item xs={11}  md={9}>
          </Grid>
        </Grid>
      </div>

    </>
  );
}