import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from "react";
import { Link } from "react-router-dom";
import "./css/NotFound.css";

export default function UnderConstruction() {
  return (
    <>
   <div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<div></div>
				<h1>404</h1>
			</div>
			<h2 className='text-primary'>PAGE NON TROUVÉE</h2>
			<p>La page que vous recherchez a peut-être été supprimée, son nom a changé ou est temporairement indisponible.</p>
			<Link className='btn btn-primary' to="/">page d'accueil</Link>
		</div>
	</div> 
    </>
  );
}
