import React from "react";
import Header from "../../components/produits/Header";
import Offre from "../../components/produits/Offre";
import PartenairesA from "../../components/produits/PartenairesA";
import Contact from "../../components/produits/Contact";
import UseCase from "../../components/produits/UseCase";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";

export default function Produit7() {
  const dataList = [
    {
      image:
        "/images/Produits/cisco-1830-access-point.webp",
      text: "Point d'accés",
    },
    {
      image:
        "/images/Produits/routers-ISR-1110.webp",
      text: "Routeur",
    },
    {
      image:
        "/images/Produits/SG350.webp",
      text: "Switch",
    },
    {
      image:
        "/images/Produits/NX-5500.webp",
      text: "Contrôlleur",
    }
  ];
   
  const dataPart = [
    { image: "images/Partenaires/4.webp" ,description: "CISCO"},
    { image: "images/Partenaires/11.webp" ,description: "EXTREME NETWORK"},
    { image: "/images/Partenaires/Ruckus_Wireless.webp" ,description: "RUCKUS"},
  ];

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos Produits d’identification et traçabilité | INFRASTRUCTURE WI-FI</title>
        <meta name="description" content="TELEDYNE aide à concevoir votre réseau Wireless pour une communication sécurisée avec vos collaborateurs tout en garantissant la pérennité de votre investissement. Notre solution personnalisée est basée sur l'étude de votre environnement et de vos besoins." />
      </Helmet>
      <div style={{ marginTop: 65 }}>
        <Header
          Title="INFRASTRUCTURE WI-FI"
          image="images/Produits/Head-produit.svg"
        ></Header>
        <Grid container alignItems="center" justifyContent="center" textAlign="justify">
          <Grid item xs={11} md={9}>
            <span wfd-id="1313" class="text-custom">
              Un réseau Wireless est une fenêtre ouverte sur votre système
              d'information. Il se construit brique par brique, et doit vous
              permettre, non seulement de communiquer avec vos collaborateurs,
              mais également d'assurer la sécurité et la pérennité de votre
              investissement.
              <br />
              <br />
              TELEDYNE vous accompagne dans l'étude de votre
              architecture Wireless, en fonction de votre environnement et de
              vos besoins.
            </span>
          </Grid>
        </Grid>
        <Offre
          list={dataList}
          max={"col-md-6 col-lg-6 text-center cust_blogteaser"}
          title="Découvrir nos offres"
        ></Offre>



        <PartenairesA list={dataPart}></PartenairesA>
      </div>
    </>
  );
}
