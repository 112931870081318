import React, { useEffect } from 'react';
import Navbar from './root/Navbar'
import { makeStyles } from '@mui/styles';
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from './root/Footer';
import HomeOneHeader from './root/HomeOneHeader';
import Drawer from './root/Drawer';
import useToggle from '../hooks/useToggle';


const useStyles = makeStyles({
    root: {
        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
    },
});

function Layout(props) {
    const [drawer, drawerAction] = useToggle(false);
    const classes = useStyles();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className={classes.root}>
            <div style={{ backgroundColor: "White" }}>
                <Drawer drawer={drawer} action={drawerAction.toggle} />
                <HomeOneHeader action={drawerAction.toggle}/>
                {props.children}
                <Footer />
            </div>
        </div>
    );
}

export default Layout;