import React from "react";
import Header from "../../components/produits/Header";
import Offre from "../../components/produits/Offre";
import PartenairesA from "../../components/produits/PartenairesA";
import Contact from "../../components/produits/Contact";
import UseCase from "../../components/produits/UseCase";

import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";

export default function Produit5() {
  
  const dataList = [
    {
      text: "Solution de pilotage opérationnel de l'inventaire physique",
      image: "/images/Produits/Logo-VS.webp",description: "VISUAL STOCK",
      produit : "VISUAL STOCK",
    },
    {
      text: "Simplification de la mobilité des entreprises et les solutions IoT",
      image: "/images/Produits/SOTI_2.webp",description: "SOTI",
      produit : "SOTI",
    },
    {
      text: "Création et impression d’étiquettes avec ou sans code-barres",
      image: "/images/Produits/bartender.webp",description: "BARTENDER",
      produit : "BARTENDER",
    },
    {
      text: "Logiciel d'étiquetage codes barres",
      image: "/images/Produits/codesoft.webp",description: "CODESOFT",
      produit : "CODESOFT",
    },
    {
      text: "WMS: Pilotage des processus logistique",
      image: "/images/Produits/wms.webp",description: "WMS - Savoye maGistor",
      produit : "WMS - Savoye maGistor",
    },
  ];

  const dataPart = [
    { image: "images/ProduitPage/img/1.webp",description: "ZEBRA" },
    { image: "images/ProduitPage/img/2.webp",description: "HONEYWELL" },
  ];

  const dataCase = [
    {
      image: "/images/Produits/VisualStockUse.webp",description: "Inventaire visual stock"
    },
    {
      image: "/images/Produits/sotiUtil.webp",description: "plateforme SOTI ONE"
    },
    {
      image: "/images/Produits/codesoft-use.webp",description: "Impression étiquettes"
    },
    {
      image: "/images/Produits/WMSUse.webp",description: "WMS entrepôt"
    },
  ];
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos Produits d’identification et traçabilité | LOGICIELS</title>
        <meta name="description" content="Découvrez nos logiciels simples et efficaces pour comprendre vos données et agir. Améliorez la visibilité de votre entreprise, gérez vos équipements et augmentez votre productivité. Notre équipe de développement est là pour vous aider à concevoir, gérer, intégrer et développer vos projets." />
      </Helmet>
      <div style={{ marginTop: 65 }}>
        <Header
          Title="LOGICIELS"
          image="images/Produits/Head-produit.svg"
        ></Header>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          textAlign="justify"
        >
          <Grid item xs={11} md={9}>
            <div className='text-custom'>
              Explorez les logiciels et leurs avantages en termes de simplicité
              et d'efficacité.
              <br />
              <br />
              <ul>
                <li>Comprenez vos données pour mieux agir.</li>
                <li>Augmentez la visibilité de votre entreprise.</li>
                <li>Facilitez la mise en œuvre de votre stratégie.</li>
                <li>Configurez, gérez et entretenez vos équipements.</li>
              </ul>
              Concevez, gérez, intégrez et développez à votre gré, pour
              accroître la productivité. Nous avons également une équipe
              complète de services de développement, qui se tient à votre
              disposition pour vous assister.
            </div>
          </Grid>
        </Grid>
        <Offre

          list={dataList}
          navigation="true"
          max={"col-md-6 col-lg-4 text-center cust_blogteaser"}
          title="Découvrir nos offres"
        ></Offre>

        <UseCase
          list={dataCase}
          Txt="Chaque logiciel peut être utiliser pour atteindre une amélioration spécifique à son utilisation soit en gestion d'inventaire, gestion de stock, controle en masse ou sur d'autre service de la chaine logistique. "
        ></UseCase>

        <PartenairesA list={dataPart}></PartenairesA>
      </div>
    </>
  );
}
