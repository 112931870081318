import Grid from "@mui/material/Grid";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../css/Partenaires.css";

export default function PartenairesA(props) {
  const options = {
    loop: false,
    nav: false,
    navigation: false,
    margin: 10,
    dots: false,
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 5,
      },
      1000: {
        items: 9,
      },
    },
  };

  return (
    <>
      <Grid
        className="pb-4"
        container
        alignItems="center"
        justifyContent="center" data-aos="fade-up"
      >
        <Grid item xs={11} md={9}>
          <div className="partenaires">
            <h2
              className="text-primary fw-bold h2-custom"
              data-lfr-editable-id="title"
              data-lfr-editable-type="text"
            >
              Nos partenaires
            </h2>
            <div class="d-flex justify-content-center">
              {props.list.map((item) => (
                <div class="item m-2">
                  <div class="card p-2 img-fluid border-0">
                    <div>
                      {" "}
                      <img
                        class="card-img-top rounded partimage"
                        src={item.image}
                        alt={item.description}
                        style={{ width: "60%", border: "1px  solid #123463" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
