import React from "react";
import Header from "../../components/produits/Header";
import Offre from "../../components/produits/Offre";
import PartenairesA from "../../components/produits/PartenairesA";
import Contact from "../../components/produits/Contact";
import UseCase from "../../components/produits/UseCase";

import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";

export default function Produit1() {
  const dataList = [
    {
      text: "HONEYWELL VM3A",
      image:
      "/images/Produits/terminaux-embarques-vm3a.webp",
        description: "HONEYWELL VM3A"
    },
    { text: "ZEBRA VC8300", image: "/images/Produits/vc8300.webp",description: "ZEBRA VC8300" },
    { text: "ZEBRA VC80x", image: "/images/Produits/VC80X.webp",description: "ZEBRA VC80x" },
    { text: "HONEYWELL VM1", image: "/images/Produits/hw-vm1.webp",description: "HONEYWELL VM1" },
  ];

  const dataPart = [
    { image: "images/ProduitPage/img/1.webp",description: "ZEBRA" },
    { image: "images/ProduitPage/img/2.webp",description: "HONEYWELL" },
  ];

  const dataCase = [
    { image: "/images/Produits/TE-3.webp",description: "Terminaux embarqués transport" },
    { image: "/images/Produits/TE1.webp",description: "Terminaux embarqués entrepôt" },
    { image: "/images/Produits/TE2.webp",description: "Terminaux embarqués dépôt" },
  ];
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos Produits d’identification et traçabilité | TERMINAUX EMBARQUÉS</title>
        <meta name="description" content="Les terminaux embarqués pour engins de manutention doivent être robustes, connectés en WiFi ou en WAN (3G/4G) et résistants à des conditions extrêmes. TELEDYNE propose désormais des modèles sous ANDROID ou Windows 10 pour remplacer Windows CE et Windows Mobile. Ces équipements améliorent la productivité et garantissent une utilisation sécurisée." />
      </Helmet>
      <div style={{ marginTop: 65 }}>
        <Header
          Title="TERMINAUX EMBARQUÉS"
          image="images/Produits/Head-produit.svg"
        ></Header>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={11} md={9}>
            <div  className='text-custom'>
              Les terminaux embarqués sur engins de manutention doivent être
              robustes, et supporter des conditions souvent extrêmes (vibration,
              choc, température, froid. Ils sont destinés à être fixés sur des
              engins de type camion, chariot élévateur, portique, transpalette
              et parfois être enlevés pour utilisation sur le terrain. Ces
              équipements permettent à vos équipes de travailler en toute
              sécurité, tout en maximisant leur niveau de productivité.&nbsp;
              <br />
              <br />
              Connectés en WIFI ou en WAN (3G/4G), ces équipements&nbsp;sont
              étudiés pour&nbsp;un fonctionnement même en plein soleil, dans
              l'obscurité&nbsp;ou par temps pluvieux. Certains modèles sont
              prévus&nbsp;peuvent supporter des températures extrêmes.&nbsp;
              <br />
              <br />
              Depuis la fin de vie annoncée de Windows CE et Windows Mobile,
              l’offre TELEDYNE s’articule désormais sur différents modèles sous
              ANDROID ou Windows 10.&nbsp;
            </div>
          </Grid>
        </Grid>
        <Offre
          list={dataList}
          max={"col-md-6 text-center cust_blogteaser"}
          title="Découvrir nos offres"
        ></Offre>

        <UseCase
          list={dataCase}
          Txt="Les terminaux embarqués peuvent être utiliser dans la fabrication , le transport ainsi que la gestion des entrepôts."
        ></UseCase>

        <PartenairesA list={dataPart}></PartenairesA>
      </div>
    </>
  );
}
