import React, { useState } from "react";
import { Link } from "react-router-dom";

function Drawer({ drawer, action }) {
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState("");
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      setSize(`${43 * getItems}px`);
      if (value === item) setItem("");
      else setItem(value);
    }
  };
  const scrollWithAction = (e) => {
    // e.preventDefault();
    action(e);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${drawer ? "active" : ""}`}
      ></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
              >
                <div id="canvas_close" className="canvas_close">
                  <a href="#" onClick={(e) => action(e)}>
                    <i className="fa fa-times"></i>
                  </a>
                </div>
                <div className="offcanvas-brand text-center mb-4">
                  <Link to="/">
                    <img
                      src="/images/logo.webp"
                      height="32"
                      alt="logo teledyne"
                    />
                  </Link>
                </div>
                <div id="menu" className="text-left text-primary">
                  <ul className="offcanvas_main_menu">
                    <li
                      onClick={(e) => handler(e, "Produits")}
                      id="Produits"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a className="pointer">Produits</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "Produits" ? itemSize : "0px",
                        }}
                      >
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/produits">Tous les produits</Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/produits/imprimantes-d-etiquettes">
                            Imprimantes d'étiquettes
                          </Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/produits/scanners-de-code-a-barres">
                            Scanners de code à barres
                          </Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/produits/terminaux-embarques">
                            Terminaux embarqués
                          </Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/produits/terminaux-mobiles">
                            Terminaux mobiles
                          </Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/produits/logiciels">Logiciels</Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="//produits/tablettes-industrielles">
                            Tablettes industrielles
                          </Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/produits/infrastructure-wi-fi">
                            Infrastructure WI-FI
                          </Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/produits/consommables-pour-impression">
                            Consommables pour impression
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      onClick={(e) => handler(e, "Solutions")}
                      id="Solutions"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a className="pointer">Solutions</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "Solutions" ? itemSize : "0px",
                        }}
                      >
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/solutions">Toutes les solutions</Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/distribution-et-points-de-vente">
                            Distribution et points de ventes
                          </Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/entrepot-logistique">
                            Entrepôt et supply chain
                          </Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/solutions-digitales-pour-l-industrie">
                            Industrie
                          </Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/sante_et_pharma">Santé et pharma</Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/banques-et-assurances">
                            Banques et assurances
                          </Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/services-publics">Services publics</Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      onClick={(e) => scrollWithAction(e)}
                      id="services"
                      className="menu-item-has-children active"
                    >
                      <Link to="/services">Services</Link>
                    </li>
                    <li
                      onClick={(e) => handler(e, "apropos")}
                      id="apropos"
                      className="menu-item-has-children active"
                    >
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a className="pointer">À propos</a>
                      <ul
                        className="sub-menu"
                        style={{
                          height: item === "apropos" ? itemSize : "0px",
                        }}
                      >
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/contactus">
                            Contactez-nous
                          </Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/a-propos">Qui sommes-nous</Link>
                        </li>
                        <li onClick={(e) => scrollWithAction(e)}>
                          <Link to="/carriere">
                            Carrière
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <a href="https://www.facebook.com/webteledyne">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/Teledyne9">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/teledyne">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/teledyne_maroc/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-widget-info">
                  <h4 className="title mb-0">Coordonnées</h4>
                  <ul>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-mail fea icon-md"
                      >
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                        <polyline points="22,6 12,13 2,6"></polyline>
                      </svg>
                      contact@teledyne.ma
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-phone fea icon-md"
                      >
                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                      </svg>
                      (+212) 0522 307 664
                      <div style={{ paddingLeft: "30px" }}>(+212) 0522 307 664
                      </div>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-map-pin fea icon-md"
                      >
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                        <circle cx="12" cy="10" r="3"></circle>
                      </svg>
                      2, rue Abdelouhab Azzakkak
                      <br />
                      Roches Noires Casablanca 20310, Maroc
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drawer;
