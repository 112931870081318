import React, { useEffect, useState } from "react";
import { createAPIEndpoint, ENDPOINTS } from "../api/Index";
import CataloguesCard from "./CataloguesCard";
import { Card, CardBody } from "reactstrap";

export default function Catalogues() {
  const [article, setArticle] = useState([]);

  const [open, setOpen] = React.useState(true);
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [catalogue, setCatalogue] = useState([]);
  const [listOptions1, setListOptions1] = useState([]);
  const [listOptions2, setListOptions2] = useState([]);
  const [data, setData] = useState([
    {
      Titre: "VISUAL STOCK",
      Description:
        "Visual Stock est une solution qui vise la supervision de l’inventaire physique, son objectif est de le piloter d’unemanière plus intelligente et plus rapide avec une vision plus claire sur le déroulement des comptages.",
      Image: "/images/ProduitPage/img/2.webp",
      Produit: "",
      Compagny: "ZEBRA",
    },
    {
      Titre: "SOTI",
      Description:
        "La plate-forme SOTI ONE vous offre un contrôle et une visibilité complets sur tous vos appareils mobiles sur le terrain : smartphones, tablettes, ordinateurs, appareils robustes, imprimantes industrielles, etc.",
      Image: "/images/ProduitPage/img/2.webp",
      Produit: "",
      Compagny: "ZEBRA",
    },
    {
      Titre: "BARTENDER",
      Description:
        "Bartender est un système d’étiquetage professionnel éprouvé qui simplifie votre chaîne logistique et votre étiquetage de conformité, au lieu d’un système compliqué générant des coûts récurrents.",
      Image: "/images/ProduitPage/img/2.webp",
      Produit: "",
      Compagny: "ZEBRA",
    },
    {
      Titre: "CODESOFT",
      Description:
        "Le logiciel Codesoft est une application d’étiquetage dédiée aux entreprises. Avec sa puissance et sa flexiblité c’est un assistant hors pair pour la réalisation de vos travaux de conception et d’édition d’étiquettes.",
      Image: "/images/ProduitPage/img/2.webp",
      Produit: "",
      Compagny: "ZEBRA",
    },
    {
      Titre: "WMS - Savoye maGistor",
      Description:
        "MaGistor est un WMS existant en SaaS ou en version sur site et adapté aux plateformes logistiques complexes, manuelles ou hautement automatisées.",
      Image: "/images/ProduitPage/img/2.webp",
      Produit: "",
      Compagny: "HONEYWELL",
    },
  ]);
  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.catalogue)
      .fetch()
      .then((res) => {
        setCatalogue(res.data);
        setListOptions1(
          res.data
            .map((item) => item.compagny)
            .filter((value, index, self) => self.indexOf(value) === index)
        );
        setListOptions2(
          res.data
            .map((item) => item.produit)
            .filter((value, index, self) => self.indexOf(value) === index)
        );
      })
      .catch((err) => console.log("err", err));
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Create an object with the selected values
    const filtre = {
      compagny: option1,
      produit: option2,
    };
    if (filtre.compagny != "" || filtre.produit != "") {
      createAPIEndpoint(ENDPOINTS.catalogue)
        .post(filtre)
        .then((res) => {
          setCatalogue(res.data);
        })
        .catch((err) => console.log("err", err));
    } else {
      createAPIEndpoint(ENDPOINTS.catalogue)
        .fetch()
        .then((res) => {
          setCatalogue(res.data);
        })
        .catch((err) => console.log("err", err));
    }
  };
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div className="blue-container">
        <div className="row">
          <div className="col-md-12">
            <div id="Titre" type="text">
              {" "}
              <h1 className="text-center text-warning pulse animated title-custom">
                Explorez notre réseau d'entreprises collaboratives
              </h1>
            </div>
            <div id="Description" type="text">
              {" "}
              <p className="text-center text-white pulse animated text-custom">
                Découvrez notre page dédiée à nos partenaires stratégiques et
                explorez leurs catalogues de produits. Nous travaillons en
                étroite collaboration avec ces sociétés renommées pour offrir à
                nos clients une gamme étendue de solutions de qualité.
                <br />
                Téléchargez les catalogues pour en savoir plus sur les produits
                et services proposés par nos partenaires, et explorez les
                opportunités qu'ils offrent pour répondre à vos besoins
                spécifiques.
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="filter-container">
          <form className="filter" onSubmit={handleSubmit}>
            <label htmlFor="option1" className="filter-label">
              Sociétés:
            </label>
            <select
              id="option1"
              className="filter-select"
              value={option1}
              onChange={(e) => setOption1(e.target.value)}
            >
              <option value="">selectionnez une option</option>
              {listOptions1.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>

            {/* <label htmlFor="option2" className="filter-label">
              Produits:
            </label>
            <select
              id="option2"
              className="filter-select"
              value={option2}
              onChange={(e) => setOption2(e.target.value)}
            >
              <option value="">selectionnez une option</option>
              {listOptions2.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select> */}

            <button type="submit" className="filter-button">
              Appliquer
            </button>
          </form>
        </div>
        {catalogue.length > 0 ? (
          <CataloguesCard Catalogues={catalogue} />
        ) : (
          <div className="no-results-card">
            <Card>
              <CardBody>
                <h5 className="text-primary">
                  Aucun résultat ne correspond à votre recherche.
                </h5>
              </CardBody>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
}
