import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Parser from "html-react-parser";
import { Link } from "react-router-dom";

export default function CardDisplay(props) {
  const myStyle = {
    fontSize: "20px",
  };
  return (
    <>
  <section className="fl-page-title">
        <div className="overlay"></div>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="page-title-inner flex">
                        <div className="page-title-heading">
                            <h2 className="text-white h2-custom">{props.title}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Grid
      container
      className="card border-0 mb-4 flex-xs-column-reverse"
      justifyContent="center"
    >
      <Grid item xs={12} md={10} lg={9}>
        <Grid className="card-content my-4 flex-xs-column-reverse" container alignItems="start">
          <Grid item xs={12} md={5}>
            <img src={props.image} class="card-img w-100 px-4 rounded" alt="..." />
          </Grid>
          <Grid item xs={12} md={7}>
            <p class="card-text text-justify px-3">
              {Parser(props.description)}{" "}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </>
  );
}
