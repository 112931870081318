import React from "react";
import Header from "../../components/produits/Header";
import Offre from "../../components/produits/Offre";
import PartenairesA from "../../components/produits/PartenairesA";
import Contact from "../../components/produits/Contact";
import UseCase from "../../components/produits/UseCase";

import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";

export default function Produit4() {
  const dataList = [
    {
      text: "ZEBRA MC33XX",
      image:
        "/images/Produits/mc3300-photography-product-front-1280x1280.webp",
        description: "ZEBRA MC33XX"
    },
    {
      text: "ZEBRA MC93XX",
      image: "/images/Produits/mc9300-photography-product-front-facing-standard-1280x1280.webp",
      description: "ZEBRA MC93XX"
    },
    {
      text: "ZEBRA TC26",
      image: "/images/Produits/TC26.webp",
      description: "ZEBRA TC26"
    },
    {
      text: "HONEYWELL CK65",
      image:
        "/images/Produits/Honeywell-CK65.webp",description: "HONEYWELL CK65"
    },
    {
      text: "HONEYWELL EDA52",
      image: "/images/Produits/EDA52.webp",description: "HONEYWELL EDA52"
    },
    {
      text: "HONEYWELL CN80",
      image: "/images/Produits/hw-cn80.webp",description: "HONEYWELL CN80"
    },
  ];

  const dataPart = [
    { image: "images/ProduitPage/img/1.webp",description: "ZEBRA"},
    { image: "images/ProduitPage/img/2.webp",description: "HONEYWELL" },
  ];

  const dataCase = [
    {
      image:
        "/images/Produits/terminaux-mobiles-use.webp",description: "Terminaux mobiles distribution"
    },
    {
      image:
        "/images/Produits/terminaux-mobiles-use2.webp",description: "Terminaux mobiles santé"
    },
    {
      image:
        "/images/Produits/terminaux-mobiles-use3.webp",description: "Terminaux mobiles entrepôt"
    },
  ];
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos Produits d’identification et traçabilité | TERMINAUX MOBILES</title>
        <meta name="description" content="Pour une solution de mobilité efficace, il est crucial d'utiliser des équipements mobiles professionnels adaptés à votre environnement de travail, tout en considérant le coût total de possession (TCO) qui inclut la maintenance et le coût du travail. TELEDYNE offre des équipements robustes et des solutions MDM pour minimiser le TCO et maximiser l'efficacité de votre solution de mobilité." />
      </Helmet>
      <div style={{ marginTop: 65 }}>
        <Header
          Title="TERMINAUX MOBILES"
          image="images/Produits/Head-produit.svg"
        ></Header>
        <Grid container alignItems="center" justifyContent="center" textAlign="justify">
          <Grid item xs={11} md={9}>
            <div className='text-custom'>
              Vous êtes souvent contraint à faire des concessions sur les
              budgets et vous limiter à déployer des équipements mobiles
              inadaptés au terrain. Un équipement mobile professionnel doit répondre à des critères de
              robustesse, de luminosité, d'étanchéité, de résistance aux chocs
              et d'autonomie batterie.
              <br /><br />
              Le coût d'acquisition ne représente qu'une partie du coût global. Dans la pratique, il est démontré qu'un PDA durci, revient finalement moins cher qu'un Smartphone "Grand Public".
              <br /><br />
              On parle, alors, de TCO (coût total de possession). En fonction de votre métier et la criticité des opérations sur le terrain, pour calculer votre TCO, il faut intégrer en plus du coût d'acquisition, la maintenance, le coût du travail et parfois le manque à gagner durant la panne : en vente mobile, une vente ratée est une vente perdue à jamais.
              <br /><br />
              TELEDYNE vous propose des équipements adaptés à votre environnement de travail, ainsi que des solutions MDM (Mobile Device Management) pour réduire au maximum le TCO de votre solution de mobilité.<br /><br />
            </div>
          </Grid>
        </Grid>
        <Offre
          list={dataList}
          max={"col-md-6 col-lg-4 text-center cust_blogteaser"}
          title="Découvrir nos offres"
        ></Offre>
        <UseCase
          list={dataCase}
          Txt="Ces terminaux mobiles peuvent être utiliser dans des différents services tel que la santé, la distribution, la fabrication ainsi que la gestion des entrepôts."
        ></UseCase>
        <PartenairesA list={dataPart}></PartenairesA>
        {/* <Contact></Contact> */}
      </div>
    </>
  );
}
