import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardDisplay from '../../components/CardDisplay';
import ReferencesWidget from '../../components/solutions/ReferencesWidget';
import OwlCarouselX4 from '../../components/OwlCarouselX4';
import { Helmet } from 'react-helmet';


export default function Publics(props) {

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  
  const DisplayOptions = {
    title: "Solutions pour les services publics",
    image:"/images/Solutions/ServicesPubliques/et55-photography-website-blog-delivery-driver-in-truck-edited-for-et56-launch-1280x720edit.webp",
    description: "<div> <p>Le digital gouverne désormais tous les efforts des gouvernements pour plus d'éfficacité dans sa relation avec les citoyens.</p><p>Que se soit pour équiper les contrôleurs à bord des trains de terminaux mobiles, la Police des frontières de tablettes mobiles, ou pour superviser les flux de camions dans les enceintes portuaires, TELEDYNE accompagne l'administration et les différents établissements publiques dans leur effort de digitalisation des services publiques.</p><p>Nous voulons apporter un savoir faire couvrant différentes compétences pointues: monétique, cyber-sécurité et développement d'applications pour fournir des solutions clé en main, intégrant les récentes technologies: RFID, NFC, Blockchain, IOT, ... etc.</p></div>", 
};


  const imageList = ["images/Solutions/ServicesPubliques/police.webp",
    "images/Solutions/ServicesPubliques/agence-nationale-des-ports.webp",
    "images/Solutions/ServicesPubliques/72.webp", 
    "images/Solutions/ServicesPubliques/Marsa-Maroc.webp",
    "images/Solutions/ServicesPubliques/35.webp"
];

  const dataList = [
    { lien: "#test", promo: false, image: 'images/Solutions/ServicesPubliques/granit-1991i-r.webp', title: ' Scanners Code à barres HONEYWELL 1991IXR', description: "La tablette robuste, multifonctions pour l'assistance à la vente en magasin" },
    { lien: "#test", promo: false, image: 'images/Solutions/ServicesPubliques/Plateforme-SOTI-ONE.webp', title: 'Plateforme SOTI ONE', description: "Logiciel EMM/MDM de configuration et supervision à distance de terminaux mobiles" },
    { lien: "#test", promo: false, image: '/images/Solutions/ServicesPubliques/Tablette_Samsung_Active_3_Pro.webp', title: ' Tablette Samsung Active 3 Pro', description: "La tablette robuste, multifonctions pour l'assistance à la vente en magasin" },
    { lien: "#test", promo: false, image: 'images/Solutions/ServicesPubliques/TERMINAUX-MOBILES-TC52-HC-ET-TC52X-HC.webp', title: 'Terminaux ZEBRA TC56', description: "Des Terminaux Robustes pour la collecte des données sur le terrain" },
    { lien: "#test", promo: false, image: 'images/Solutions/ServicesPubliques/Tablette_ZEBRA_ET56.webp', title: ' Tablette ZEBRA ET56', description: "La tablette robuste, multifonctions pour l'accès à distance aux plateformes digitales et la collecte itinérante de données" },
    { lien: "#test", promo: false, image: 'images/Solutions/ServicesPubliques/tc26-tc21-mobile-computer.webp', title: 'Smartphone ZEBRA TC26', description: "L'un des termianux les plus performants, dernier né des Smartphones Industriels chez Zebra"},
 ]


  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos solutions digitaux| Solutions pour les services publics</title>
        <meta
          name="description"
          content="Les gouvernements cherchent à améliorer leur relation avec les citoyens en se tournant vers le numérique. TELEDYNE fournit des solutions clé en main pour aider les administrations et les établissements publics à se digitaliser. Les compétences proposées couvrent la monétique, la cyber-sécurité et le développement d'applications, intégrant des technologies de pointe telles que RFID, NFC, Blockchain et IoT."
        />
      </Helmet>
      <div style={{ marginTop: 65 }}>
        <CardDisplay  {...DisplayOptions}></CardDisplay>
        <Grid container spacing={2} alignItems="center" justifyContent="center" >
        <Grid item xs={11} md={9}> 
            <OwlCarouselX4 h1="Produits et services proposés dans le secteur public" list={dataList}></OwlCarouselX4>
          </Grid>
          <Grid item xs={11} md={9}> 
            <ReferencesWidget list={imageList}></ReferencesWidget>
          </Grid>
          <Grid item xs={11} md={9}> 
          </Grid>


        </Grid>
      </div>

    </>
  );
}