import React from 'react';
import '../css/HomeSlider.css' 

 export default function HomeSlider() { 
  return (
      <>
    <div className="fragment_7615" style={{marginTop:"65px"}}>
    <section id="carousel">
        <div className="carousel slide text-left text-primary" data-ride="carousel" id="carousel-1">
            <div className="carousel-inner" role="listbox">
                <div className="carousel-item" >
                    <div className="jumbotron pulse animated hero-nature carousel-hero">
                    <div id="Titre1" type="text"> <h1 className="text-left hero-subtitle hero-title text-warning text-uppercase"   style={{marginLeft:'12%'}}>Technologie de pointe pour vos Inventaires</h1></div>
                      <div id="Descrption1" type="text">  <p className="text-left hero-subtitle text-white" style={{marginLeft:'12%',paddingLeft: '5px'}}>La technologie RFID devient accessible et vous permet de réaliser des inventaires fiables en un temps record</p> </div>
                      </div>
                </div>
                <div className="carousel-item">
                    <div className="jumbotron pulse animated hero-photography carousel-hero">
                        <div id="Titre2" type="text"> <h1 className="text-left hero-subtitle hero-title text-warning text-uppercase"  style={{marginLeft:'12%',paddingLeft: '5px'}} >MAÎTRISEZ VOTRE CHAÎNE LOGISTIQUE<br /></h1></div>
                      <div id="Descrption2" type="text">  <p className="text-left hero-subtitle text-white" style={{marginLeft:'12%',paddingLeft: '5px'}} >Suivez en temps réel vos opérations de stockage en adoptant un flux parfaitement numéris </p></div>
                       </div>
                </div>
				 <div className="carousel-item">
                    <div className="jumbotron pulse animated hero-photography2 carousel-hero">
                    <div id="Titre3" type="text">  <h1 className="text-left hero-subtitle hero-title text-warning text-uppercase" style={{marginLeft:'12%',paddingLeft: '5px'}}>Boostez les opérations sur le terrain<br /></h1></div>
                    <div id="Descrption3" type="text">    <p className="text-left hero-subtitle text-white" style={{marginLeft:'12%',paddingLeft: '5px'}} >Terminaux et Tablettes adaptés au travail intensif de votre personnel itinérant</p></div>
                        </div>
                </div>
                <div className="carousel-item active">
                    <div className="jumbotron pulse animated hero-technology carousel-hero">
                      <div id="Titre4" type="text">  <h1 className="text-left hero-subtitle hero-title text-warning text-uppercase" style={{marginLeft:'12%',paddingLeft: '5px'}}>Traçabilité et Suivi en temps réel<br /></h1></div>
                      <div id="Descrption4" type="text">  <p className="text-left hero-subtitle text-white" style={{marginLeft:'12%',paddingLeft: '5px'}} >Solutions de traçabilité mobile couvrent l'identification, la collecte de données et le traitement temps réel de vos opérations  </p></div>
                             </div>
                </div>
            </div>
            <div><a className="carousel-control-prev" href="#carousel-1" role="button" data-slide="prev"><i className="fa fa-chevron-left"></i><span className="sr-only">Previous</span></a><a className="carousel-control-next" href="#carousel-1" role="button" data-slide="next"><i className="fa fa-chevron-right"></i><span className="sr-only">Next</span></a></div>
            <ol
                className="carousel-indicators">
                <li data-target="#carousel-1" data-slide-to="0"></li>
                <li data-target="#carousel-1" data-slide-to="1"></li>
                <li data-target="#carousel-1" data-slide-to="2" className="active"></li>
			    <li data-target="#carousel-1" data-slide-to="3"></li>
                </ol>
        </div>
    </section>
</div>
  
    </>
  );
}