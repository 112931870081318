import Grid from "@mui/material/Grid";
import "../produits/Header.css";

export default function Header(props) {
  return (
    <div className="my-2">
      <div
        style={{
          backgroundImage: "URL('" + props.image + "')",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div class="fragment_7204 bounce animated">
          <div class="container">
            <div class="row" style={{ height: "150px" }}>
              <div class="col-md-12">
                <h1 class="hero-subtitle h1-custom text-white center  ml-4">
                  <strong>{props.Title} </strong>
                  <h5 class="text-left text-primary">
                    {props.SecTitle}
                    <br />
                  </h5>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={9}>
          <div style={{ textAlign: "justify" }} class="mb-4 mt-4">
            <span class="text-primary">{props.text}</span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
