import React from 'react';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faFacebookSquare, faInstagramSquare, faLinkedinIn, faTwitter, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Box } from '@mui/material';
import { NavLink } from "react-router-dom";


export default function Footer() {
  return (
    <Box component="footer">

      <footer className="footer bg-footer footer-bar py-5">


        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0  py-1">
              <p className="text-light text-justify text-custom">TELEDYNE est une ESN spécialisée dans la mise en place des systèmes d'informations mobiles dans les secteurs de l'industrie, la logistique, le transport et la supply-chain.<br />
                Nous construisons l'avenir de la digitalisation des systèmes d’information en Afrique et offrons à la fois, une expertise locale et un service de proximité de qualité internationale.​​</p>
            </div>
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0 pt-2" style={{ paddingRight: '10px' }}>
              <h6 className="text-uppercase font-weight-bold mb-2 text-white" style={{ borderBottom: 'solid', borderColor: '#FCD807' }} ><a className="text-white">À propos</a></h6>
              <ul className="list-unstyled mb-0 py-6">
                <li className="mb-2"><a style={{ fontFamily: "'Bloody',arial", borderColor: '#FCD807;', paddingRight: '10px', color: '#FCD807' }} >&gt;</a><NavLink
                    exact
                    to="/a-propos"
                    activeClassName="active"
                    className="text-light"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Qui sommes-nous
                  </NavLink></li>
                <li className="mb-2"><a style={{ fontFamily: "'Bloody',arial", borderColor: '#FCD807;', paddingRight: '10px', color: '#FCD807' }} >&gt;</a><NavLink
                    exact
                    to="/carriere"
                    activeClassName="active"
                    className="text-light"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Carrières
                  </NavLink></li>
                <li className="mb-2"><a style={{ fontFamily: "'Bloody',arial", borderColor: '#FCD807;', paddingRight: '10px', color: '#FCD807' }} >&gt;</a><NavLink
                    exact
                    to="/contactus"
                    activeClassName="active"
                    className="text-light"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Contact
                  </NavLink></li>
              </ul>
              <div id="social-media-links" style={{ borderTop: 'solid', borderColor: '#FCD807', marging: '20px' }}>
                <ul className="nav flex-row mx-auto d-flex justify-content-start py-2">
                  <li className="mx-2">

                    <div id="facebook">
                      <a
                        href="https://www.facebook.com/webteledyne"  >
                        <FontAwesomeIcon icon={faFacebookSquare} size="2x" style={{ color: '#FCD807' }} />
                      </a>
                    </div>
                  </li>
                  <li className="mx-2">
                    <div id="twitter">
                      <a
                        href="https://twitter.com/Teledyne9"
                      > <FontAwesomeIcon icon={faTwitterSquare} size="2x" style={{ color: '#FCD807' }} />
                      </a>
                    </div>
                  </li>
                  <li className="mx-2">
                    <div id="linked-in">
                      <a className="icon-linkedin icon-2x"
                        href="https://www.linkedin.com/company/teledyne"
                      >
                        <FontAwesomeIcon icon={faLinkedinIn} size="2x" style={{ color: '#FCD807' }} />
                      </a>
                    </div>
                  </li>
                  <li className="mx-2">
                    <div id="instagram">
                      <a className="icon-instagram icon-2x" style={{ color: '#FCD807;' }}
                        href="https://www.instagram.com/teledyne_maroc/"
                        target="_blank">  <FontAwesomeIcon icon={faInstagramSquare} size="2x" style={{ color: '#FCD807' }} />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-lg-0  py-2">
              <h6 className="text-uppercase font-weight-bold mb-4 text-white">Rejoignez la communauté teledyne</h6>
              <div className="p-1 rounded border">
                <div className="input-group">
                  <input type="email" placeholder="Email ....." aria-describedby="button-addon1" className="form-control border-0 shadow-0" />

                </div>
              </div>

              <div className="container">
                <div className="row py-4" >
                  <a href="javascript:void(0)" className="btn rounded btn-md btn-block" style={{ backgroundColor: '#FCD807' }} >S'inscrire à la Newsletter</a>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container" id='counter'>

          <div className="d-flex justify-content-between flex-wrap flex-sm-row flex-column text-custom">
            <div className="mr-auto p-2">©2023 Teledyne. Tous droits réservés.</div>
            <div className="mr-auto p-2">Conditions générales de vente.</div>
            <div className="mr-auto p-2">Mentions légales</div>
            <div className="mr-auto p-2">Confidentialités</div>
            <div className="mr-auto p-2">FAQ</div>
          </div>

        </div>

      </footer>

    </Box>
  );
}