import Grid from "@mui/material/Grid";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../css/Partenaires.css";

export default function PartenairesW(props) {
  const options = {
    loop: false,
    nav: false,
    navigation: false,
    margin: 10,
    dots: false,
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 6,
      },
    },
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" data-aos="zoom-in">
        <Grid item xs={11} md={9}>
          <div className="partenaires  py-4">
            <h2
              className="text-primary fw-bold h2-custom mb-4"
              data-lfr-editable-id="title"
              data-lfr-editable-type="text"
            >
              Nos partenaires
            </h2>
            <OwlCarousel className="owl-theme" {...options}>
              {props.list.map((item) => (
                <div className="item border-0">
                  <div className="card img-fluid border-0">
                    <img
                      className="card-img-top rounded"
                      src={item.image}
                      alt={item.description}
                      style={{width: "60%", height: "auto", border: "1px  solid #1B4A76"}}/>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
