import React, { useState, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Grid, TextField, Alert } from "@mui/material";
import Paper from "@mui/material/Paper";
import InfoContact from "../components/InfoContact";
import useForm from "../hooks/useForm";
import { createAPIEndpoint, ENDPOINTS } from "../api/Index";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export default function Carriere() {
  const getFreshModel = () => ({
    FirstName: "",
    LastName: "",
    Email: "",
    Tel: "",
    fileUploader: "",
    Message: "",
  });
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(getFreshModel);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const goToForm = () => {
    window.scrollTo({
      top: 1300,
      behavior: "smooth",
    });
  };
  const sliderRef = useRef();
  const sliderNext = () => {
    sliderRef.current.slickNext();
  };
  const sliderPrev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    autoplay: true,
    arrows: false,
    dots: false,
    speed: 2000,
    autoplaySpeed: 7000,
  };
  const onSubmit = (data) => sendData(data);
  function sendData(e) {
    e.preventDefault();
    var form = document.querySelector("form");
    var formData = new FormData(form);
    if (validate()) {
      createAPIEndpoint(ENDPOINTS.SendingEmail)
        .post(formData)
        .then((res) => {
          setSuccess(true);
          setValues(getFreshModel);
        })
        .catch((err) => {
          setSuccess(false);
          setValues(getFreshModel);
        });
    }
  }

  const validate = (e) => {
    let temp = {};
    temp.Email = /\S+@\S+\.\S+/.test(values.Email)
      ? ""
      : "Email n'est pas valide";
    temp.FirstName = values.FirstName != "" ? "" : "Ce champ est obligatoire";
    temp.LastName = values.LastName != "" ? "" : "Ce champ est obligatoire";
    temp.Tel = values.Tel != "" ? "" : "Ce champ est obligatoire";
    temp.fileUploader =
      values.fileUploader != "" ? "" : "Ce champ est obligatoire";
    temp.Message = values.Message != "" ? "" : "Ce champ est obligatoire";
    setErrors(temp);
    return Object.values(temp).every((x) => x == "");
  };

  const myStyle = {
    backgroundImage: "url('/images/carriere.webp')",
    height: "90vh",
    marginTop: "65px",
    minHeight: "600px",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Carrières | Commercial, technique, achats, logistique ou autres
        </title>
        <meta
          name="description"
          content="Boostez votre carrière en rejoignant la famille Teledyne. Postuler à nos offres ou tout simplement joignez votre CV pour une candidature spontané."
        />
      </Helmet>
      <div style={myStyle}>
        <Grid
          container
          style={{
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={1}
            height="90vh"
            style={{ alignContent: "center", justifyContent: "center" }}
          >
            <Grid item md={5} xs={11}>
              <h1 className="text-uppercase text-warning pb-0 pulse animated h1_custom">
                Carrières
              </h1>
              <Typography
                variant="h6"
                component="div"
                className="text-justify text-white text-custom center pt-4 pb-2"
              >
                Nous sommes ravis d’accueillir d’excellents talents de tous
                bords dans notre équipe. Venez explorer à quoi ressemble votre
                chemin.
              </Typography>
              <Typography
                variant="h6"
                component="div"
                className="text-justify text-white text-custom mb-4"
              >
                Recherchez nos postes vacants et postulez pour un poste qui vous
                convient et vous permet de poursuivre vos passions. Rejoignez
                TELEDYNE et changez le monde avec nous.
              </Typography>
              <div class="row bounce pointer" onClick={goToForm}>
                <div class="col-sm-12 text-center mb-4">
                  <a class="text-warning h2">Postulez</a>
                </div>
                <span class="scroll-down"></span>
              </div>
            </Grid>
            <Grid item md={5} xs={12}></Grid>
          </Grid>
        </Grid>
      </div>
      <section className="w-100 my-5" id="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2
                className="text-primary fw-bold h2-custom mb-4"
                data-lfr-editable-id="title"
                data-lfr-editable-type="text"
              >
                Nos offres d'emploi
              </h2>
            </div>
            <div className="col-lg-12">
              <div className="appie-testimonial-2-box">
                <div
                  className="appie-testimonial-slider-2"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={sliderPrev}
                    className="prev slick-arrow"
                    style={{ display: "block" }}
                  >
                    <i class="fa-solid fa-arrow-left"></i>
                  </span>
                  <Slider ref={sliderRef} {...settings}>
                    <div className="appie-testimonial-slider-2-item">
                      <div className="item">
                        <div className="content">
                          <div className="h4 text-white my-4">
                            Cadre Administration des ventes
                          </div>
                          <p className="text-justify">
                            En tant que Gestionnaire des Achats et Ventes, vous
                            aurez la responsabilité de coordonner les activités
                            d'achats et de ventes depuis la commande initiale
                            jusqu'à la livraison et la facturation. Vous
                            assurerez la gestion du stock, supervisant les
                            mouvements d’entrées et de sorties, et organiserez
                            des inventaires physiques réguliers ainsi que les
                            opérations de correction de stock sur l’ERP. Vous
                            serez également responsable de la facturation des
                            clients et des fournisseurs, veillant à ce que
                            toutes les opérations d’achats et de ventes soient
                            conformes aux réglementations en vigueur. En tant
                            qu'interlocuteur privilégié de vos clients et
                            fournisseurs, vous représenterez les intérêts de
                            l’entreprise, garantissant ainsi une communication
                            efficace et des relations solides avec toutes les
                            parties prenantes.
                          </p>
                          <div className="author-info">
                            <a
                              href="/files/Cadre_Administration_des_ventes.pdf"
                              download
                              className="h6 title text-warning"
                            >
                              Télécharger les détails de l'offre{" "}
                              <i class="fa-solid fa-arrow-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="appie-testimonial-slider-2-item">
                      <div className="item">
                        <div className="content">
                          <div className="h4 text-white my-4">
                            Ingénieur Commercial
                          </div>
                          <p className="text-justify">
                            En tant que Responsable Commercial, vous serez
                            chargé de conduire les activités commerciales auprès
                            de grandes entreprises industrielles, de services,
                            et des administrations publiques. Votre mission
                            inclut la prospection et l'élaboration de
                            propositions commerciales, intégrant matériel,
                            logiciels et services d’accompagnement. Vous
                            participerez activement aux phases de présentation
                            avant-vente, de démarrage et de réception des
                            projets. En tant qu'interlocuteur privilégié de vos
                            clients et prospects, vous représenterez les
                            intérêts de l’entreprise, assurant la gestion
                            complète des opérations, depuis l’initialisation
                            jusqu’à la réception définitive, incluant le
                            paiement et la libération des documents et cautions.
                          </p>
                          <div className="author-info">
                            <a
                              href="/files/Ingénieur_Commercial.pdf"
                              download
                              className="h6 title text-warning"
                            >
                              Télécharger les détails de l'offre{" "}
                              <i class="fa-solid fa-arrow-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                  <span
                    onClick={sliderNext}
                    className="next slick-arrow"
                    style={{ display: "block" }}
                  >
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-0 pt-4 m-4" id="carriere">
        <div className="container pt-5 rounded border">
          <div class="row justify-content-center">
            {success && (
              <Alert
                onClose={() => {
                  setSuccess(null);
                }}
                severity="success"
              >
                Votre candidature a été envoyée avec succés
              </Alert>
            )}
            {success == false && (
              <Alert
                onClose={() => {
                  setSuccess(null);
                }}
                severity="error"
              >
                une erreur s'est produite, votre candidature n'est pas envoyée
              </Alert>
            )}
            <div class="col-12 text-center">
              <div class="section-title m-0">
                <div class="titles">
                  <h3 class="title title-line h3-custom text-uppercase mb-4 pb-4">
                    Candidature spontanée
                  </h3>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <section class="section py-0">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div class="custom-form mb-sm-30">
                    <form noValidate onSubmit={sendData}>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-12 col-md-0">
                              <div class="form-group"></div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                              <div class="form-group">
                                <TextField
                                  fullWidth
                                  name="FirstName"
                                  id="FirstName"
                                  label="Nom"
                                  type="text"
                                  value={values.FirstName}
                                  onChange={handleInputChange}
                                  {...(errors.FirstName && {
                                    error: true,
                                    helperText: "ce champ est obligatoire",
                                  })}
                                />
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                              <div class="form-group">
                                <TextField
                                  fullWidth
                                  name="LastName"
                                  id="lastName"
                                  type="text"
                                  label="Prénom"
                                  value={values.LastName}
                                  onChange={handleInputChange}
                                  {...(errors.LastName && {
                                    error: true,
                                    helperText: "ce champ est obligatoire",
                                  })}
                                />
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                              <div class="form-group">
                                <TextField
                                  fullWidth
                                  name="Email"
                                  id="email"
                                  type="text"
                                  label="Email"
                                  value={values.Email}
                                  onChange={handleInputChange}
                                  {...(errors.Email && {
                                    error: true,
                                    helperText: "ce champ est obligatoire",
                                  })}
                                />
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                              <div class="form-group">
                                <TextField
                                  fullWidth
                                  name="Tel"
                                  id="tele"
                                  type="tel"
                                  label="Numéro de téléphone"
                                  value={values.Tel}
                                  onChange={handleInputChange}
                                  {...(errors.Tel && {
                                    error: true,
                                    helperText: "ce champ est obligatoire",
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <Paper
                              style={{
                                width: "100%",
                                height: "100%",
                                marginTop: "30px",
                                padding: "10px",
                              }}
                            >
                              <label for="fileUploader">
                                Choisissez votre CV (PDF, Word)
                              </label>
                              <input
                                type="File"
                                className="form-control"
                                name="fileUploader"
                                value={values.fileUploader}
                                id="fileUploader"
                                accept=".doc,.docx,.pdf"
                                onChange={handleInputChange}
                              />
                              {errors.fileUploader && (
                                <span className="px-3 text-danger">
                                  Veuillez joindre votre CV
                                </span>
                              )}
                            </Paper>
                          </div>
                          <div
                            class="form-group"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <TextField
                              fullWidth
                              multiline
                              rows={20}
                              name="Message"
                              id="comments"
                              value={values.Message}
                              label="* Votre message​ :"
                              onChange={handleInputChange}
                              {...(errors.Message && {
                                error: true,
                                helperText: "ce champ est obligatoire",
                              })}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 text-right mb-2">
                          <input
                            disabled={loading}
                            type="submit"
                            id="submit"
                            name="send"
                            class="submitBnt btn btn-primary"
                            value="Envoyer"
                          />
                          <div id="simple-msg"></div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <InfoContact lg={4} />
          </section>
        </div>
      </section>
    </>
  );
}
