import React from "react";
import Header from "../../components/produits/Header";
import Offre from "../../components/produits/Offre";
import PartenairesA from "../../components/produits/PartenairesA";
import Contact from "../../components/produits/Contact";
import Grid from "@mui/material/Grid";
import UseCase from "../../components/produits/UseCase";
import { Helmet } from "react-helmet";

export default function Produit1() {
  const dataList = [
    {
      text: "Scanners ultra-durcis pour la production et la gestion d’entrepôt",
      image: "/images/Produits/Scanner-ultra-durcis.webp",
      description: "Scanner ultra-durcis LI3600-ER"
    },
    {
      text: "Scanners universels pour le commerce et la distribution",
      image: "/images/Produits/Distribution1.webp",
      description: "Scanners universels MP7000"
    },
    {
      text: "Pistolets et lecteurs pour l’accueil et comptoir",
      image: "/images/Produits/Scanners-pour-l_accueil-et-comptoir.webp",
        description: "Pistolets & lecteurs pour accueil & comptoir"
    },
    {
      text: "Scanners pour le transport et la logistique",
      image: "/images/Produits/Scanners-Transport-et-Logistique.webp",
        description: "Scanners transport & logistique"
    },
    {
      text: "Scanners pour secteur de la santé",
      image: "/images/Produits/Scanners-Sante.webp",
        description: "Scanners pour secteur santé"
    },
    { text: "RFID", image: "/images/Produits/RFID.webp",description: "Scanner RFID RFD9030" },
  ];
  const dataCase = [
    {  image: '/images/Produits/scannerUse.webp',description: "Scanner réception" }, 
    {  image: '/images/Produits/scannerUse2.webp',description: "Scanner entrepôt" },
    {  image: '/images/Produits/scannerUse3.webp',description: "Scanner santé" },   
] 

  const dataPart = [
    { image: "images/ProduitPage/img/1.webp",description: "ZEBRA" },
    { image: "images/ProduitPage/img/2.webp",description: "HONEYWELL" },
  ];
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos Produits d’identification et traçabilité | SCANNERS DE CODE À BARRES</title>
        <meta name="description" content="Chez TELEDYNE, vous pouvez trouver des lecteurs pour différents cas d'utilisation, tels que des scanners pour les caisses, des pistolets pour l'entrepôt, des scanners antibactériens pour la santé, des pistolets avec support mains libres pour la production, et des lecteurs fixes pour les convoyeurs. Vous pouvez choisir le type d'optique et les symbologies pour les codes à barres 1D et 2D." />
      </Helmet>
      <div style={{ marginTop: 65 }}>
        <Header
          Title="SCANNERS DE CODE À BARRES"
          image="images/Produits/Head-produit.svg"
        ></Header>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          textAlign="justify"
        >
          <Grid item xs={11} md={9}>
            <div className='text-custom'>
              <p>
                Les codes à barres se distinguent par leur facilité
                d’utilisation et leur coût accessible. Les lecteurs code à
                barres existent désormais pour tous les budgets, et pratiquement
                tous les usages.
              </p>
              <p>
                Retrouvez chez TELEDYNE les lecteurs code à barres adaptés à
                votre cas d’utilisation.
              </p>
              <ul>
                <li>
                  Dans le commerce et la distribution : Scanners fixes ou
                  mobiles, filaires ou radio pouvant apporter souplesse et
                  performance au passage à la caisse .
                </li>
                <li>
                  En entrepôt : Pistolets industriels pour lecture à courte ou
                  longue distance, robustesse et utilisation en liaison avec
                  tout type de terminal portable ou embarqué .
                </li>
                <li>
                  Dans la santé : Scanners antibactériens, destinés à un
                  nettoyage aux solvants et produits autorisés dans le secteur
                  de la santé .
                </li>
                <li>
                  En atelier de production : Pistolets et lecteurs avec support
                  main libre, ainsi que des lecteurs fixes pour montage sur
                  convoyeur .
                </li>
              </ul>
              <p>
                Dans les gammes de scanners proposés, choisissez le type
                d’optique entre imageur ou laser, ainsi que les symbologies
                codes à barres décodés : 1D pour les codes à barres usuels (UPC,
                EAN, CODE 39, CODE 128, … etc.) et 2D pour les codes à barres
                bidimensionnels (QR Code, Datamatrix, PDF 417, … etc.)
              </p>
            </div>
          </Grid>
        </Grid>
        <Offre
          list={dataList}
          max={"col-md-6 col-lg-4 text-center cust_blogteaser"}
          title="Découvrir nos offres"
        ></Offre>
        <UseCase
        list={dataCase}
        Txt="Les scanners code à barres ont des utilisation divers dans des différents services tel que la santé, la distribution, la fabrication ainsi que la gestion des entrepôts."></UseCase>
        <PartenairesA list={dataPart}></PartenairesA>
      </div>
    </>
  );
}
