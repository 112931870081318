import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
                <ul>
                    <li>
                        <a className="text-primary mx-3">
                            Produits <i className="fa fa-angle-down"></i>
                        </a>
                        <ul className="sub-menu  mt-1">
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link  to="/produits">Tous les produits</Link>
                            </li>
                            <hr class="dropdown-divider"/>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link  to="/produits/imprimantes-d-etiquettes">Imprimantes d'étiquettes</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link  to="/produits/scanners-de-code-a-barres">Scanners de code à barres</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link  to="/produits/terminaux-embarques">Terminaux embarqués</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link  to="/produits/terminaux-mobiles">Terminaux mobiles</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link  to="/produits/logiciels">Logiciels</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link to="/produits/tablettes-industrielles">Tablettes industrielles</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link to="/produits/infrastructure-wi-fi">Infrastructure WI-FI</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link to="/produits/consommables-pour-impression">Consommables pour impression</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a className="text-primary mx-3">
                            Solutions <i className="fa fa-angle-down"></i>
                        </a>
                        <ul className="sub-menu mt-1">
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link to="/Solutions">Toutes les solutions</Link>
                            </li>
                            <hr class="dropdown-divider"/>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link to="/distribution-et-points-de-vente">Distribution et points de ventes</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link to="/entrepot-logistique">Entrepôt et supply chain</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link to="/solutions-digitales-pour-l-industrie">Industrie</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link to="/sante_et_pharma">Santé et pharma</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link to="/banques-et-assurances">Banques et assurances</Link>
                            </li>
                            <li onClick={() => window.scrollTo(0, 0)}>
                                <Link to="/services-publics">Services publics</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="mx-3" onClick={() => window.scrollTo(0, 0)}>
                        <Link to="/services">Services</Link>
                    </li>
                    <li  className="mx-3" onClick={() => window.scrollTo(0, 0)}>
                        <Link className='main-btn' to="/contactus">Contactez-nous</Link>
                    </li>
                </ul>
        </>
    );
}

export default Navigation;
