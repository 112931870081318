import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CardDisplay from "../../components/CardDisplay";
import DistributionWidget from "../../components/solutions/DistributionWidget";
import ReferencesWidget from "../../components/solutions/ReferencesWidget";
import ProjetWidget from "../../components/solutions/ProjetWidget";
import OwlCarouselX4 from "../../components/OwlCarouselX4";
import { Helmet } from "react-helmet";

export default function Entrepot(props) {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const DisplayOptions = {
    title: "Nous digitalisons votre entrepôt logistique",
    image:
      "/images/Solutions/Entrepot/Nous-digitalisons-votre-entrepot-logistiqueedit.webp",
    description:
      "<div> <p>L'entrepôt de stockage est un lieu de productivité et de gestion rationnelle de la performance. Les opérations de réception, stockage de marchandises doivent se faire avec un contrôle strict, tant qualitatif que quantitatif. Les préparations et expéditions des commandes exigent dextérité et rapidité d'exécution.&nbsp;</p><p>En collaboration avec les éditeurs de logiciels WMS, TELEDYNE offre un ensemble de solutions pour les entrepôts logistiques. Notre expérience nous permet de vous conseiller dans le choix du bon équipement d'impression ou de collecte de données, que ce soit pour les opérateurs piétons ou sur les engins de manutention. Nous proposons également des solutions WIFI les plus performantes, pour assurer une couverture optimale entre les rayons et les allées ainsi qu'une supervision distribuée et intelligente. Enfin, pour l'adressage des rayons et alvéoles, nos étiquettes de balisage sont conçues pour favoriser les bons réflexes de productivité des préparateurs et agents de manutention.&nbsp;</p><p>Que vous soyez dans un environnement à température ambiante ou dirigée (jusqu'à -30°C), que les produits manipulés soient explosifs (Gaz, Hydrocarbures, Minoterie, ... etc.) ou non, TELEDYNE vous proposera toujours les solutions techniques qui vous permettront de disposer de l'équipement le plus adapté ainsi que les certifications nécessaires à votre environnement propre.</p> </div>",
  };

  const imageList = [
    "images/Solutions/Entrepot/Coca-Cola.webp",
    "images/Solutions/Entrepot/Marsa-Maroc.webp",
    "images/Solutions/Entrepot/26.webp",
    "images/Solutions/Entrepot/24.webp",
    "images/Solutions/Entrepot/Maroc-Telecom.webp",
    "images/Solutions/Entrepot/33.webp",
  ];

  const dataList = [
    {
      lien: "#test",
      promo: false,
      image: "images/Solutions/Entrepot/terminal-embarque-vc8300-zebra.webp",
      title: " Terminal embarqué ZEBRA VC8XXX",
      description:
        "Pour les engins de manutention, chariots mobiles et véhicules",
    },
    {
      lien: "#test",
      promo: false,
      image:
        "images/Solutions/Entrepot//DS3608-HPDS3678-HP-1D2D-ULTRA-RUGGED-SCANNER.webp",
      title: " Scanners Code à barres ZEBRA DS36xx",
      description:
        "L'un des termianux les plus performants, dernier né des Smartphones Industriels chez Zebra",
    },
    {
      lien: "#test",
      promo: false,
      image: "images/Solutions/Entrepot/terminaux-mobiles-pda-zebramc93.webp",
      title: "Terminal ZEBRA MC93xx",
      description:
        "Gamme de terminaux Ultra Robustes avec choix de lecteurs Code à barres courte à très longue distance",
    },
    {
      lien: "#test",
      promo: false,
      image: "images/Solutions/Entrepot/zt410.webp",
      title: "Imprimantes ZEBRA ZT4xx",
      description:
        "Gamme d'imprimantes d'étiquettes industrielles, pour le balisage des rayons",
    },
    {
      lien: "#test",
      promo: false,
      image: "images/Solutions/Entrepot/ck75-honeywell-terminaux-pda.webp",
      title: "Terminal Honeywell CK75",
      description: "Des terminaux Robustes pour les opérateurs en entrepôt",
    },
    {
      lien: "#test",
      promo: false,
      image: "images/Solutions/Entrepot/etiquettes-de-Balisage.webp",
      title: " Étiquettes de Balisage",
      description:
        "Pour faciliter l'identification des alvéoles et les Scan à la volée",
    },
    {
      lien: "#test",
      promo: false,
      image: "images/Solutions/Entrepot/Imprimantes-Mobiles-ZEBRA-ZQ5xx.webp",
      title: " Imprimantes Mobiles ZEBRA ZQ5xx",
      description:
        "Gamme d'imprimante mobiles pour l'identification des colis en préparation",
    },
    {
      lien: "#test",
      promo: false,
      image: "images/Solutions/Entrepot/Pupitre-Mobiles.webp",
      title: "Pupitre Mobiles",
      description:
        "Ensemble Mobile sur batterie pour les contrôles à la réception et à l'expédition",
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nos solutions digitaux| Entrepôt et supply chain</title>
        <meta
          name="description"
          content="En collaboration avec des éditeurs de logiciels WMS, Teledyne vous propose des solutions pour vos entrepôts logistiques. On offre de plus des conseils sur l'équipement d'impression et de collecte de données, ainsi que des solutions WIFI performantes pour une couverture optimale. Les étiquettes de balisage sont également proposées pour améliorer la productivité. Teledyne peut fournir des solutions pour différents environnements, y compris ceux avec des températures contrôlées ou des matériaux dangereux, et assure que l'équipement et les certifications sont adaptés à l'environnement spécifique."
        />
      </Helmet>
      <div style={{ marginTop: 65 }}>
        <CardDisplay {...DisplayOptions}></CardDisplay>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={11} md={9}>
            <OwlCarouselX4
              h1="Produits phares et services proposés en milieu industriel"
              list={dataList}
            ></OwlCarouselX4>
          </Grid>
          <Grid item xs={11} md={9}>
            <ReferencesWidget list={imageList}></ReferencesWidget>
          </Grid>
          <Grid item xs={11} md={9}></Grid>
        </Grid>
      </div>
    </>
  );
}
