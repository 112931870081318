import { Box, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createAPIEndpoint, ENDPOINTS } from "../../api/Index";
import "../css/Services.css";

export default function Services() {
  const [article, setArticle] = useState([]);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.article)
      .fetch()
      .then((res) => {
        setArticle(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <Grid
        className=" pb-4"
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={11} md={9}>
          <div className="noservices" data-aos="zoom-in">
            <h2
              className="text-primary fw-bold h2-custom"
              data-div-id="title"
              data-div-type="text"
            >
              Nos services
            </h2>
            <div
              className="text-secondary fw-normal lh-1 my-2 text-custom"
              data-div-id="description"
              data-div-type="text"
            >
              Nous vous proposons un ensemble de services de proximité, un
              support et une expertise locale, mis en place et perfectionnés au
              fil des projets réalisés depuis 1995.
            </div>
            
            <div class="containerx grid-container">
              {article.map(
                (item) =>
                  item.shortTitre != "" &&
                  item.type == 2 && (
                    <div class="card grid-item">
                      <div class="face face1">
                        <div class="content  text-center">
                          <img src={item.shortImage} />
                          <h6 className="text-primary text-center">
                            {item.titre}
                          </h6>
                        </div>
                      </div>
                      <div class="face face2">
                        <div class="content">
                          <p>{item.shortDescription}</p>
                          <Link
                            onClick={() => window.scrollTo(0, 0)}
                            className="btn btn-light-primary"
                            to="/services"
                          >
                            En savoir plus
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
