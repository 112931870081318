import Home from "./pages/Home";
import Solutions from "./pages/Solutions";
import Services from "./pages/Services";
import Produits from "./pages/Produits";
import Produit1 from "./pages/produits/Produit1";
import Produit2 from "./pages/produits/Produit2";
import Produit3 from "./pages/produits/Produit3";
import Produit4 from "./pages/produits/Produit4";
import Produit5 from "./pages/produits/Produit5";
import Produit6 from "./pages/produits/Produit6";
import Produit7 from "./pages/produits/Produit7";
import Produit8 from "./pages/produits/Produit8";
import Distribution from "./pages/solutions/Distribution";
import Industrie from "./pages/solutions/Industrie";
import Entrepot from "./pages/solutions/Entrepot";
import Sante from "./pages/solutions/Sante";
import Banque from "./pages/solutions/Banque";
import Publics from "./pages/solutions/Publics";
import Contact from "./pages/Contact";
import UnderConstruction from "./pages/UnderConstruction";
import ListPartenaire from "./pages/crud/partenaire/ListPartenaire";
import Apropos from "./pages/Apropos";
import Carriere from "./pages/Carriere";
import DescriptionProduits from "./pages/produits/DescriptionProduits";
import NotFound from "./pages/UnderConstruction";
import Catalogues from "./pages/Catalogues";
import CataloguesV2 from "./pages/CataloguesV2";
const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "/solutions",
    element: <Solutions />,
  },
  {
    path: "/*",
    element: <NotFound />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/telechargements",
    element: <Catalogues />,
  },
  {
    path: "/catalog",
    element: <CataloguesV2 />,
  },
  {
    path: "/produits",
    element: <Produits />,
  },
  {
    path: "/distribution-et-points-de-vente",
    element: <Distribution />,
  },

  {
    path: "/solutions-digitales-pour-l-industrie",
    element: <Industrie />,
  },
  {
    path: "/entrepot-logistique",
    element: <Entrepot />,
  },
  {
    path: "/sante_et_pharma",
    element: <Sante />,
  },
  {
    path: "/contactus",
    element: <Contact />,
  },
  {
    path: "/banques-et-assurances",
    element: <Banque />,
  },

  {
    path: "/services-publics",
    element: <Publics />,
  },
  {
    path: "/produits/imprimantes-d-etiquettes",
    element: <Produit1 />,
  },

  {
    path: "/produits/scanners-de-code-a-barres",
    element: <Produit2 />,
  },
  {
    path: "/produits/terminaux-embarques",
    element: <Produit3 />,
  },
  {
    path: "/produits/terminaux-mobiles",
    element: <Produit4 />,
  },
  {
    path: "/produits/logiciels",
    element: <Produit5 />,
  },
  {
    path: "/produits/tablettes-industrielles",
    element: <Produit6 />,
  },
  {
    path: "/produits/infrastructure-wi-fi",
    element: <Produit7 />,
  },
  {
    path: "/produits/consommables-pour-impression",
    element: <Produit8 />,
  },
  {
    path: "/UnderConstruction",
    element: <UnderConstruction />,
  },
  {
    path: "/ListPartenaire",
    element: <ListPartenaire />,
  },
  {
    path: "/a-propos",
    element: <Apropos />,
  },
  {
    path: "/carriere",
    element: <Carriere />,
  },
  {
    path: "/produits/logiciels/Description",
    element: <DescriptionProduits />,
  },
];

export default AppRoutes;
