import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { createAPIEndpoint, ENDPOINTS } from "../api/Index";
import "./css/Solutions.css";

export default function Solutions() {

  const [article, setArticle] = useState([]);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GetSolutions)
      .fetch()
      .then((res) => {
        setArticle(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nos solutions digitaux| Supply chain, industrie, santé et autres</title>
        <meta name="description" content="Teledyne vous assure des solutions sur des différents secteurs pour tout type de domaine d’activité en faisant appel au digital. On est présent pour la distribution et points de vente, pour les entrepôts et la supply chain, pour l’industrie, pour la santé et pharma, pour les banques et assurances, ainsi que pour les services publics." />
      </Helmet>
      <div>
        <div className="blue-container">
          <div className="row">
            <div className="col-md-12">
              <div id="Titre" type="text">
                {" "}
                <h1 className="text-center text-warning pulse animated title-custom">
                  Nos solutions
                </h1>
              </div>
              <div id="Description" type="text">
                {" "}
                <p className="text-center text-white pulse animated text-custom">
                  TELEDYNE intervient dans les différents secteurs ou le digital
                  prend une place prépondérante. <br/>Nos solutions sont conçues pour
                  répondre aux besoins de votre domaine d'activité.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home">
        <main className="page-content px-4 pb-2" data-aos="fade"
              data-bs-hover-animate="pulse">
          {article.map((item) => (
            item.type == 0 &&
            <div key={item.idRefArticle}
              className="card border rounded box-shadow-hover mx-3 my-2"
              
            >
              <NavLink to={item.slug} onClick={() => window.scrollTo(0, 0)}>
                <img
                  src={item.image}
                  className="card-img-top cardImage pointer rounded-top"
                  alt={item.titre}

                />
                <div className="card-body text-center text-hover CardTitre">
                  <h5
                    className="card-title h6 text-primary stretched-link CardTitre mb-0"
                    data-lfr-editable-type="text"
                  >
                    {item.titre}
                  </h5>
                </div>
              </NavLink>
            </div>
          ))}
        </main>
      </div>
    </>
  );
}
