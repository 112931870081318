import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";

export default function Apropos() {
  const myStyle = {
    backgroundImage: "url('/images/QuiSommesNous/aboutus.webp')",
    height: "90vh",
    marginTop: "65px",
    minHeight: "600px",
    fontSize: "50px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Qui sommes-nous | Présentation de l’entreprise</title>
        <meta
          name="description"
          content="Teledyne est une entreprise qui vous assure l’amélioration et le développement de vos entités en se basant sur les nouvelles technologies dans le domaine des systèmes d’informations logistiques, transport et supply chain. "
        />
      </Helmet>
      <div style={myStyle} >
        <Grid
          container
          style={{
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={1}
            height="90vh"
            style={{ alignContent: "center", justifyContent: "center" }}
          >
            <Grid item md={5} xs={11}>
              <h1 className="text-uppercase text-warning pb-0 pulse animated h1_custom">
              Qui sommes-nous ?
              </h1>
              <Typography
                variant="h6"
                component="div"
                className="text-justify text-white text-custom center pt-4 pb-2"
              >
                Nous sommes une entreprise spécialisée dans le domaine des
                  systèmes d'informations logistiques, transport et supply chain
                  en général.
              </Typography>
              <Typography
                variant="h6"
                component="div"
                className="text-justify text-white text-custom mb-4"
              >
                Nous intervenons sur le marché Marocain et apportons un
                savoir-faire local, permettant à nos clients de compter sur une
                équipe pluridisciplinaire de proximité. Nos produits et Services
                sont adaptés au marché local et ses spécificités. Notre ambition
                est d'accompagner l'élan du Maroc et des entreprises Marocaines
                pour améliorer leur compétitivité logistique et réduire le poids
                des coûts logistique dans l'assiette du consommateur.
              </Typography>
            </Grid>
            <Grid item md={5} xs={12}></Grid>
          </Grid>
        </Grid>
      </div>
      <Grid
        data-aos="zoom-in"
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={9} xs={12}>
          <Card sx={{ display: "flex", margin: "40px" }}>
            <Box
              data-aos="fade-in"
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent sx={{ flex: "1 0 auto", padding: 1 }}>
                <Typography
                  variant="h2"
                  paddingTop={0}
                  paddingBottom="30px"
                  component="div"
                  className="text-center text-primary center h2-custom pt-2"
                >
                  Chaque projet est unique
                </Typography>
                <Grid container spacing={1}>
                  <Typography
                    data-aos="fade-right"
                    variant="subtitle1"
                    padding={2}
                    component="div"
                    className="text-justify text-primary center text-custom"
                  >
                    <i class="fa-solid fa-genderless"></i> Aucun projet ne
                    ressemble aux autres. Souvent, nos clients nous demandent
                    des équipements, voire des solutions et l'on se rend compte
                    qu'ils ont en fait besoin de reconsidérer tout un panel de
                    leur organisation ou bien de leur système d'information.
                  </Typography>
                  <Typography
                    data-aos="fade-left"
                    variant="subtitle1"
                    padding={2}
                    component="div"
                    className="text-justify text-primary center text-custom"
                  >
                    <i class="fa-solid fa-genderless"></i> C'est pourquoi, nous
                    vous accompagnons à en étudier les particularités de votre
                    projet. Nous examinons avec vous l'impact sur votre activité
                    et vérifions la faisabilité, tenant compte de vos objectifs,
                    vos budgets et vos contraintes.
                  </Typography>
                  <Typography
                    data-aos="fade-right"
                    variant="subtitle1"
                    padding={2}
                    component="div"
                    className="text-justify text-primary center text-custom"
                  >
                    <i class="fa-solid fa-genderless"></i> Dans cette démarche,
                    nous nous engageons à vous conseiller la solution qui nous
                    semble la plus appropriée, et la plus adaptée. Dans le cas
                    où nous estimons que des compétences particulières sont
                    nécessaires (et ce sera souvent le cas), nous vous le
                    faisons savoir et vous aidons à avoir accès à ces
                    compétences.
                  </Typography>
                  <Typography
                    data-aos="fade-left"
                    variant="subtitle1"
                    padding={2}
                    component="div"
                    className="text-justify text-primary center text-custom"
                  >
                    <i class="fa-solid fa-genderless"></i> Nous ne vous
                    accompagnerons dans votre projet que dans la mesure où nous
                    sommes convaincus que la solution retenue vous apportera les
                    gains escomptés, que sa faisabilité soit avérée et que son
                    budget soit parfaitement justifié et crédible.
                  </Typography>
                </Grid>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
