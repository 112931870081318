import React, { useEffect, useState } from "react";
import HomeSlider from "../components/root/HomeSlider";
import HomeSolution from "../components/root/HomeSolution";
import Partenaires from "../components/root/Partenaires";
import Produits from "../components/root/Produits";
import Services from "../components/root/Services";
import Contact from "../components/root/Contact";
import Confiance from "../components/root/Confiance";
import { createAPIEndpoint, ENDPOINTS } from "../api/Index";
import { Helmet } from "react-helmet";

export default function Home() {
  const [societe, setSociete] = useState([]);
  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.societe)
      .fetch()
      .then((res) => {
        setSociete(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Teledyne | Produits d’identification, Solutions digitales</title>
        <meta
          name="description"
          content="Découvrez nos solutions métier, nos produits, ainsi que nos services reliés aux systèmes d’informations mobiles dans les secteurs de l’industrie, la logistique, le transport et la supply-chain."
        />
      </Helmet>
      <HomeSlider />
      <HomeSolution />
      <Partenaires partenaire={societe} />
      <Produits />
      <Services />
      <Contact />
      <Confiance confiance={societe} />
    </>
  );
}
