import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import useStateContext from "../../hooks/useStateContext";
import "../produits/Offre.css";

export default function Offre(props) {
  const navigate = useNavigate();
  const { context, setContext } = useStateContext();
  const goToDescription = (item) => {
      setContext(item.description);
      navigate("/produits/logiciels/Description", {state: item.produit});
    }
  return (
    <>
      <Grid
        className="mt-4"
        container
        alignItems="center"
        justifyContent="center" data-aos="zoom-in"
      >
        <Grid item xs={11} md={12}>
          <div class="fragment_9039">
            <div>
              <div class="container">
                <h2
                  class="text-primary fw-bold h2-custom"
                  data-div-id="title"
                  data-div-type="text"
                >
                  {props.title}
                </h2>
                <div class="cust_bloglistintro">
                  <p
                    style={{
                      marginLeft: "34px",
                      color: "rgba(255,255,255,0.5)",
                      fontSize: "14px",
                    }}
                  ></p>
                </div>
                <div class="row">
                  {props.list.map((item) => (
                    <div
                      data-bs-hover-animate="bounce"
                      class={props.max}
                    >
                      <a>
                        <div id="image1" type="image">
                          <img width="auto" height="250px" src={item.image} alt={item.description}/>
                        </div>
                      </a>
                      {props.navigation == "true" ? <a className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary" onClick={() => goToDescription(item)}>
                          {item.text}
                        </a>: <h5 className="text-primary font-weight-bold">
                          {item.text}
                        </h5>}
                      
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
