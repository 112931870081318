import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../css/solutions/Distribution.css";
import { Box, CircularProgress } from "@mui/material";

export default function ReferencesWidget(props) {
  const options = {
    items: 1,
    margin: 20,
    dots: false,
    rewind: true,
    autoplay: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 5,
      },
      1000: {
        items: 8,
      },
    },
  };
  return (
    <>
      <div class="partenaires my-4" data-aos="zoom-in">
        <h2 class="text-primary fw-bold h2-custom">Quelques références</h2>

        <Grid container alignItems="center" justifyContent="center">
          {props.list.map((item) => (
            <Grid item xs={4} md={2}>
              <div class="item m-2">
                <div class="card img-fluid border-0">
                  <div id="cardImage1" type="image">
                    {" "}
                    <img
                      class="card-img-top rounded"
                      src={item}
                      alt="Card image"
                      style={{
                        width: "80%",
                        height: "auto",
                        border: "1px  solid #123463",
                        padding: "10px"
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
}
