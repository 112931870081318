import Grid from '@mui/material/Grid'; 
import React, { useState, useEffect } from 'react'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../css/solutions/Distribution.css'  
import $ from 'jquery' 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 


 export default function DistributionWidget() { 


 

    const carouselOptions = {
        margin: 20,
        nav:true,
        dots: false, 
        autoplay: true,
        navText : ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'] ,
        slideBy: 'page',
        responsive: {
          0: {
            items: 1,
            rows: 1 //custom option not used by Owl Carousel, but used by the algorithm below
          },
          768: {
            items: 2,
            rows: 2 //custom option not used by Owl Carousel, but used by the algorithm below
          },
          991: {
            items: 2,
            rows: 2 //custom option not used by Owl Carousel, but used by the algorithm below
          }
        }
    };
      
 
  return (
      <> 
    
      <div class="fragment_8824">
    <h3 class="text-primary fw-bold mb-20 mt-4 mb-4 h2-custom" data-lfr-editable-id="title" data-lfr-editable-type="text">
        Produits phares et services proposés dans le retail
    </h3>

    <OwlCarousel className='owl-theme' {...carouselOptions} >
        <div className="slide mx-2" data-slide-index="0" data-lfr-editable-id="link0" data-lfr-editable-type="link">
            <a >
                <div class="product_item ">
                    
                    <div class="product_image align-self-start">
                        <img src="images/Solutions/DistributionWidget/Tablette-ZEBRA-ET56.webp"
                            alt="Terminal embarqué ZEBRA VC8XXX"/>
                    </div>
                    <div class="product_values align-self-center">
                        <div class="product_title">
                            <h4 class="text-primary" data-lfr-editable-id="title0" data-lfr-editable-type="text">
                                Tablette ZEBRA ET56</h4>
                        </div>
                        <div class="product_desc">
                            <p class="truncate" data-lfr-editable-id="desc0" data-lfr-editable-type="text">La tablette
                                robuste, multifonctions pour l'assistance à la vente en magasin</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>


        <div class="slide" data-slide-index="1" data-lfr-editable-id="link1" data-lfr-editable-type="link">
            <a >
                <div class="product_item ">
                  
                    <div class="product_image">
                        <img src="images/Solutions/DistributionWidget/tc26-tc21-mobile-computer.webp"
                            alt=" Scanners Code à barres ZEBRA DS36xx" />
                    </div>
                    <div class="product_values">
                        <div class="product_title">
                            <h4 class="text-primary" data-lfr-editable-id="title1" data-lfr-editable-type="text">
                                Smartphone ZEBRA TC26</h4>
                        </div>
                        <div class="product_desc">
                            <p class="truncate" data-lfr-editable-id="desc1" data-lfr-editable-type="text">Le dernier né
                                des Smartphones Industriels de terminaux Zebra pour le secteur de la distribution</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>


        <div class="slide" data-slide-index="2" data-lfr-editable-id="link2" data-lfr-editable-type="link">
            <a>
                <a class="product_item ">
                    <div class="product_sale">
                        <p class="promo" data-lfr-editable-id="promo2" data-lfr-editable-type="text"></p>
                    </div>
                    <div class="product_image">
                        <img src="images/Solutions/DistributionWidget/Terminal-HONEYWELL-SCANPAL-EDA51.webp"
                            alt="Terminal HONEYWELL SCANPAL EDA51"/>
                    </div>
                    <div class="product_values">
                        <div class="product_title">
                            <h4 class="text-primary" data-lfr-editable-id="title2" data-lfr-editable-type="text">
                                Terminal HONEYWELL SCANPAL EDA51</h4>
                        </div>
                        <div class="product_desc">
                            <p class="truncate" data-lfr-editable-id="desc2" data-lfr-editable-type="text">Parfait
                                compromis entre Prix et Performance adapté au secteur du Retail</p>
                        </div>
                    </div>
                </a>
            </a>
        </div>


        <div class="slide" data-slide-index="3" data-lfr-editable-id="link3" data-lfr-editable-type="link">
            <a>
                <div class="product_item ">
                    <div class="product_sale">
                        <p class="promo" data-lfr-editable-id="promo3" data-lfr-editable-type="text"></p>
                    </div>
                    <div class="product_image">
                        <img src="images/Solutions/DistributionWidget/Imprimantes-ZEBRA-ZT4xx.webp" alt="Imprimantes ZEBRA ZT4xx" />
                    </div>
                    <div class="product_values">
                        <div class="product_title">
                            <h4 class="text-primary" data-lfr-editable-id="title3" data-lfr-editable-type="text">
                                Imprimantes ZEBRA ZT4xx</h4>
                        </div>
                        <div class="product_desc">
                            <p class="truncate" data-lfr-editable-id="desc3" data-lfr-editable-type="text">Gamme
                                d'imprimantes d'étiquettes industrielles, pour le balisage des rayons</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>


        <div class="slide" data-slide-index="4" data-lfr-editable-id="link4" data-lfr-editable-type="link">
            <a>
                <div class="product_item ">
                    <div class="product_sale">
                        <p class="promo" data-lfr-editable-id="promo4" data-lfr-editable-type="text"></p>
                    </div>
                    <div class="product_image">
                        <img src="images/Solutions/DistributionWidget/zq511-photography-product-right-600x600.webp"
                            alt="Imprimantes Mobiles ZEBRA ZQ511" />
                    </div>
                    <div class="product_values">
                        <div class="product_title">
                            <h4 class="text-primary" data-lfr-editable-id="title4" data-lfr-editable-type="text">
                                Imprimantes Mobiles ZEBRA ZQ511</h4>
                        </div>
                        <div class="product_desc">
                            <p class="truncate" data-lfr-editable-id="desc4" data-lfr-editable-type="text">Gamme
                                d'imprimante mobiles pour l'identification des produits et des rayons</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>





        <div class="slide" data-slide-index="5" data-lfr-editable-id="link5" data-lfr-editable-type="link">
            <a >
                <div class="product_item ">
                    <div class="product_sale">
                        <p class="promo" data-lfr-editable-id="promo5" data-lfr-editable-type="text"></p>
                    </div>
                    <div class="product_image">
                        <img src="images/Solutions/DistributionWidget/Plateforme-SOTI-ONE.webp" alt="Plateforme SOTI ONE" />
                    </div>
                    <div class="product_values">
                        <div class="product_title">
                            <h4 class="text-primary" data-lfr-editable-id="title5" data-lfr-editable-type="text">
                                Plateforme SOTI ONE</h4>
                        </div>
                        <div class="product_desc">
                            <p class="truncate" data-lfr-editable-id="desc5" data-lfr-editable-type="text">Logiciel
                                EMM/MDM de configuration et supervision à distance de terminaux mobiles</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>




        <div class="slide" data-slide-index="6" data-lfr-editable-id="link6" data-lfr-editable-type="link">
            <a >
                <div class="product_item ">
                    <div class="product_sale">
                        <p class="promo" data-lfr-editable-id="promo6" data-lfr-editable-type="text"></p>
                    </div>
                    <div class="product_image">
                        <img src="images/Solutions/DistributionWidget/Imprimante-DASCOM-MIP480.webp" alt="  Imprimante DASCOM MIP480" />
                    </div>
                    <div class="product_values">
                        <div class="product_title">
                            <h4 class="text-primary" data-lfr-editable-id="title6" data-lfr-editable-type="text">
                                Imprimante DASCOM MIP480</h4>
                        </div>
                        <div class="product_desc">
                            <p class="truncate" data-lfr-editable-id="desc6" data-lfr-editable-type="text">Imprimante
                                mobile matricielle pour éditer les documents en multiple exemplaires dans le véhicule
                            </p>
                        </div>
                    </div>
                </div>
            </a>
        </div>


        <div class="slide" data-slide-index="7" data-lfr-editable-id="link7" data-lfr-editable-type="link">
            <a >
                <div class="product_item ">
                    <div class="product_sale">
                        <p class="promo" data-lfr-editable-id="promo7" data-lfr-editable-type="text"></p>
                    </div>
                    <div class="product_image">
                        <img src="images/Solutions/DistributionWidget/Installation-WIFI.webp" alt="Pupitre Mobiles" />
                    </div>
                    <div class="product_values">
                        <div class="product_title">
                            <h4 class="text-primary" data-lfr-editable-id="title7" data-lfr-editable-type="text">
                                Installation WIFI</h4>
                        </div>
                        <div class="product_desc">
                            <p class="truncate" data-lfr-editable-id="desc7" data-lfr-editable-type="text">Solution WIFI
                                Industrielle avec supervision distribuée et monitoring à distance</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>

        <div class="slide" data-slide-index="8" data-lfr-editable-id="link8" data-lfr-editable-type="link">
            <a >
                <div class="product_item ">
                    <div class="product_sale">
                        <p class="promo" data-lfr-editable-id="promo8" data-lfr-editable-type="text"></p>
                    </div>
                    <div class="product_image">
                        <img src="images/Solutions/DistributionWidget/4-SAV.webp" alt="Maintenance et SAV" />
                    </div>
                    <div class="product_values">
                        <div class="product_title">
                            <h4 class="text-primary" data-lfr-editable-id="title8" data-lfr-editable-type="text">
                                Maintenance et SAV</h4>
                        </div>
                        <div class="product_desc">
                            <p class="truncate" data-lfr-editable-id="desc8" data-lfr-editable-type="text">équipements
                                fournis avec extension de garantie à 3 ou 5 ans, pour éviter toute interruption de
                                service</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>


        <div class="slide" data-slide-index="9" data-lfr-editable-id="link9" data-lfr-editable-type="link">
            <a >
                <div class="product_item ">
                    <div class="product_sale">
                        <p class="promo" data-lfr-editable-id="promo9" data-lfr-editable-type="text"></p>
                    </div>
                    <div class="product_image">
                        <img src="images/Solutions/DistributionWidget/Bourse-de-Fret.webp" alt="Bourse de Fret"/>
                    </div>
                    <div class="product_values">
                        <div class="product_title">
                            <h4 class="text-primary" data-lfr-editable-id="title9" data-lfr-editable-type="text">
                                Bourse de Fret</h4>
                        </div>
                        <div class="product_desc">
                            <p class="truncate" data-lfr-editable-id="desc9" data-lfr-editable-type="text">Première
                                plateforme digitale de mutualisation de Transport Marchandises</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
   

    </OwlCarousel>

</div> 
    </>
  );


}