import { Alert, Grid, useFormControl } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InfoContact from "../components/InfoContact";
import { createAPIEndpoint, ENDPOINTS } from "../api/Index";
import useForm from "../hooks/useForm";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  reverseColumn: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
}));

export default function Contact() {
  const getFreshModel = () => ({
    Societe: "",
    FullName: "",
    Email: "",
    Tel: "",
    Motif: "",
    Objet: "",
    Message: "",
  });
  const classes = useStyles();
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(getFreshModel);
  const [stats, setStats] = useState(null);
  const [success, setSuccess] = useState(null);
  const [errorName, setErrorName] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const motif = [
    {
      value: "1",
      label: "Demande de devis",
    },
    {
      value: "2",
      label: "Besoin d'assistance",
    },
    {
      value: "3",
      label: "Réclamation",
    },
    {
      value: "4",
      label: "Autre",
    },
  ];

  const onSubmit = (data) => sendData(data);
  function sendData(e) {
    e.preventDefault();
    var form = document.querySelector("form");
    var formData = new FormData(form);
    if (validate()) {
      setLoading(true);
      createAPIEndpoint(ENDPOINTS.SendingEmail)
        .post(formData)
        .then((res) => {
          if (res.data == "ERROR!") {
            setSuccess(false);
            setLoading(false);
            setValues(getFreshModel);
          } else {
            setSuccess(true);
            setLoading(false);
            setValues(getFreshModel);
          }
        })
        .catch((err) => {
          setSuccess(false);
          setLoading(false);
          setValues(getFreshModel);
        });
    }
  }
  const validate = (e) => {
    let temp = {};
    temp.Email = /\S+@\S+\.\S+/.test(values.Email)
      ? ""
      : "Email n'est pas valide";
    temp.FullName = values.FullName != "" ? "" : "Ce champ est obligatoire";
    temp.Societe = values.Societe != "" ? "" : "Ce champ est obligatoire";
    temp.Tel = values.Tel != "" ? "" : "Ce champ est obligatoire";
    temp.Motif = values.Motif != "" ? "" : "Ce champ est obligatoire";
    temp.Objet = values.Objet != "" ? "" : "Ce champ est obligatoire";
    temp.Message = values.Message != "" ? "" : "Ce champ est obligatoire";
    setErrors(temp);
    return Object.values(temp).every((x) => x == "");
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact</title>
        <meta
          name="description"
          content="Contactez-nous à propos de tout ce qui concerne notre entreprise ou nos services."
        />
      </Helmet>
      <div className="blue-container">
        <div className="row">
          <div className="col-md-12">
            <div id="Titre" type="text">
              {" "}
              <h1 className="text-center text-warning pulse animated title-custom">
                Contactez-nous
              </h1>
            </div>
            <div id="Description" type="text">
              {" "}
              <p className="text-center text-white pulse animated text-custom">
                Contactez-nous à propos de tout ce qui concerne notre entreprise
                ou nos services. <br />
                Nous ferons de notre mieux pour vous répondre dès que possible.
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
      <Grid
      className="my-5"
        container
        style={{
          justifyContent: "center",
        }}
      >
        <Grid item lg={7} md={8} xs={12}>
          <Card
            className="border"
            sx={{
              marginLeft: 2,
              marginRight: 2,
            }}
          >
            <CardContent class="pt-4 pb-4  px-0">
              <section class="section py-0" id="contact">
                <div class="container">
                  <section class="section py-0">
                    <div class="container">
                      <div class="row justify-content-center">
                        {success && (
                          <Alert
                            className="mb-2"
                            onClose={() => {
                              setSuccess(null);
                            }}
                            severity="success"
                          >
                            Votre message est bien reçu
                          </Alert>
                        )}
                        {success == false && (
                          <Alert
                            className="mb-2"
                            onClose={() => {
                              setSuccess(null);
                            }}
                            severity="error"
                          >
                            une erreur s'est produite, votre message n'est pas
                            envoyé
                          </Alert>
                        )}
                        <div class="col-lg-12">
                          <div class="custom-form">
                            <form
                              noValidate
                              onSubmit={sendData}
                              encType="multipart/form-data"
                            >
                              <div class="row">
                                <div class="col-6 mb-4">
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name="Societe"
                                    id="societe"
                                    label="Société"
                                    type="text"
                                    value={values.Societe}
                                    onChange={handleInputChange}
                                    {...(errors.Societe && {
                                      error: true,
                                      helperText: "ce champ est obligatoire",
                                    })}
                                  />
                                </div>
                                <div class="col-6 mb-4">
                                  <TextField
                                    fullWidth
                                    name="FullName"
                                    size="small"
                                    id="FullName"
                                    type="text"
                                    label="Nom"
                                    value={values.FullName}
                                    onChange={handleInputChange}
                                    {...(errors.FullName && {
                                      error: true,
                                      helperText: "ce champ est obligatoire",
                                    })}
                                  />
                                </div>
                                <div class="col-6 mb-4">
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name="Email"
                                    id="email"
                                    type="text"
                                    label="Email"
                                    value={values.Email}
                                    onChange={handleInputChange}
                                    {...(errors.Email && {
                                      error: true,
                                      helperText: "Saisissez un email valide",
                                    })}
                                  />
                                </div>
                                <div class="col-6 mb-4">
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name="Tel"
                                    id="tel"
                                    type="tel"
                                    label="Numéro de téléphone"
                                    value={values.Tel}
                                    onChange={handleInputChange}
                                    {...(errors.Tel && {
                                      error: true,
                                      helperText:
                                        "Saisissez un numéro de téléphone valide",
                                    })}
                                  />
                                </div>
                                <div class="col-md-6 col-sm-12 mb-4">
                                  <TextField
                                    fullWidth
                                    size="small"
                                    id="Motif"
                                    name="Motif"
                                    select
                                    label="Motif de contact"
                                    value={values.Motif}
                                    onChange={handleInputChange}
                                    {...(errors.Motif && {
                                      error: true,
                                      helperText: "Séléctionnez le motif",
                                    })}
                                  >
                                    {motif.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </div>
                                <div class="col-md-6 col-sm-12 mb-4">
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name="Objet"
                                    id="Objet"
                                    label="Objet"
                                    value={values.Objet}
                                    onChange={handleInputChange}
                                    {...(errors.Objet && {
                                      error: true,
                                      helperText:
                                        "Saisissez l'Objet de message",
                                    })}
                                  />
                                </div>
                                <div class="col-12">
                                  <div class="form-group ">
                                    <TextField
                                      fullWidth
                                      multiline
                                      rows={20}
                                      name="Message"
                                      id="comments"
                                      value={values.Message}
                                      label="* Votre message​ :"
                                      onChange={handleInputChange}
                                      {...(errors.Message && {
                                        error: true,
                                        helperText: "Saisissez votre message",
                                      })}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 text-right">
                                  <input
                                    disabled={loading}
                                    type="submit"
                                    id="submit"
                                    name="send"
                                    class="submitBnt btn btn-primary"
                                    value="Envoyer"
                                  />
                                  <div id="simple-msg"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={3} md={4} xs={11}>
          <InfoContact lg={12} />
        </Grid>
      </Grid>
      <div class="row m-4">
        <iframe
          width="100%"
          height="350"
          frameborder="0"
          scrolling="no"
          src="https://maps.google.co.in/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=TELEDYNE, Casablanca&amp;output=embed"
        ></iframe>
      </div>
    </>
  );
}
