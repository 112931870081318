import React, { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

export default function CataloguesV2() {
  const [prefile, setPrefile] = useState("null");
  const [zebra, setZebra] = useState([
    {
      Titre: "ZEBRA",
      catalogue: [
        {
          Titre: "Terminaux mobiles",
          href: "https://drive.google.com/file/d/1hzA8IbYQ9ua9qdHKfNrGhza_JyaFAkjC/view?usp=sharing",
          image:
            "/images/Catalogue/Zebra/Terminaux_mobiles_professionnels.webp",
        },
        {
          Titre: "Gammes de scanners",
          href: "https://drive.google.com/file/d/19WIFSVMgG0Mvan4Pj6QUVTDRkMCLrbvm/view?usp=sharing",
          image: "/images/Catalogue/Zebra/Gammes_de_scanners.webp",
        },
        {
          Titre: "Imprimantes",
          href: "https://drive.google.com/file/d/1qMUH5Q8cj7HeSbsmm0OzFJcoDq8sSHse/view?usp=sharing",
          image: "/images/Catalogue/Zebra/Imprimantes_thermiques.webp",
        },
        {
          Titre: "Portefeuille RFID",
          href: "https://drive.google.com/file/d/1xGNN5OTqKoKP5WKSykSrQRd4txPAjWms/view?usp=sharing",
          image: "/images/Catalogue/Zebra/Portefeuille-RFID.webp",
        },
        {
          Titre: "Tablettes",
          href: "https://drive.google.com/file/d/1OX0R27rAdHyhak0g5yC5oPcs6TZsNIWU/view?usp=sharing",
          image: "/images/Catalogue/Zebra/tablettes.webp",
        },
      ],
    },
  ]);
  const [honeywell, setHoneywell] = useState([
    {
      Titre: "HONEYWELL",
      catalogue: [
        {
          Titre: "Scanners",
          href: "https://drive.google.com/file/d/1owJcBtTqrbQjBpa0xxq70z2kqNpFZpy4/view?usp=sharing",
          image: "/images/Catalogue/Honeywell/Barcode-scanners.webp",
        },
        {
          Titre: "Imprimantes",
          href: "https://drive.google.com/file/d/1bdp6lN4dJtdC3LdI_4FhepzrLJ2o-dGM/view?usp=sharing",
          image: "/images/Catalogue/Honeywell/Barcode-printers.webp",
        },
        {
          Titre: "Terminaux mobiles",
          href: "https://drive.google.com/file/d/1FysXyWE-cTxXbr0FX0dE20Wn-LSddCyZ/view?usp=sharing",
          image: "/images/Catalogue/Honeywell/mobile-computers.webp",
        },
        {
          Titre: "RFID",
          href: "https://drive.google.com/file/d/1dj3RMLoAX864Ihtf-UsOgnVqfVAZd0A2/view?usp=sharing",
          image: "/images/Catalogue/Honeywell/rfid.webp",
        },
        {
          Titre: "Terminaux embarqués",
          href: "https://drive.google.com/file/d/1Qm6bBe5PYTFLIK0FG4wkncxdg-lFDCMJ/view?usp=sharing",
          image: "/images/Catalogue/Honeywell/terminaux-embarques.webp",
        },
        {
          Titre: "Tablettes",
          href: "https://drive.google.com/file/d/13G6VCKMP0AuH4g4r0XkWAAqgCCEGtIrv/view?usp=sharing",
          image: "/images/Catalogue/Honeywell/tablettes.webp",
        },
      ],
    },
  ]);
  const [soti, setSoti] = useState([
    {
      Titre: "SOTI",
      catalogue: [
        {
          Titre: "Gestion éprouvé de la mobilité",
          href: "https://drive.google.com/file/d/1N1DBBEqN-BPvFwJnBMyT1Iz7Q-XWqP5s/view?usp=sharing",
          image: "/images/Catalogue/Soti/soti-mobicontrol.webp",
        },
        {
          Titre: "Gestion de l'IoT simplifiée",
          href: "https://drive.google.com/file/d/1HBsMxTB_Ud0eix-cGcH3_q5srstxdjYw/view?usp=sharing",
          image: "/images/Catalogue/Soti/soti-connect.webp",
        },
        {
          Titre: "Intelligence diagnostique",
          href: "https://drive.google.com/file/d/1tLmHxloJYH3ZtsNHc2s0Yu-1LDaIK16E/view?usp=sharing",
          image: "/images/Catalogue/Soti/soti-xsight.webp",
        },
        {
          Titre: "Accés sécurisé à la SOTI ONE Platform",
          href: "https://drive.google.com/file/d/1gUJKE45mYHu3hSI5lwQpwyI0dFfqBHls/view?usp=sharing",
          image: "/images/Catalogue/Soti/soti-identity.webp",
        },
        {
          Titre: "Réponse rapide à la mobilité",
          href: "https://drive.google.com/file/d/1RGl-YnNwNzJP2zgzeNqdmYnzuOioLk79/view?usp=sharing",
          image: "/images/Catalogue/Soti/soti-snap.webp",
        },
        {
          Titre: "Collaboration au bout des doigts",
          href: "https://drive.google.com/file/d/15WkM2PTwvnAf48ep6HMM7OeM8xU_hL7D/view?usp=sharing",
          image: "/images/Catalogue/Soti/soti-pulse.webp",
        },
        {
          Titre: "SOTI ONE: forfaits et offres",
          href: "https://drive.google.com/file/d/14_mLvFtAkInfaNGCLVr-1C1mJfccy42i/view?usp=sharing",
          image: "/images/Catalogue/Soti/bundles-and-offerings.webp",
        },
        {
          Titre: "Gestion des appareils solides",
          href: "https://drive.google.com/file/d/1dTZkcGbPDI3iGGKgu8kDeAOIVJSXLW-x/view?usp=sharing",
          image: "/images/Catalogue/Soti/rugged-device.webp",
        },
        {
          Titre: "Gestion de la mobilité: pour les systémes de vente",
          href: "https://drive.google.com/file/d/1-2QFeXXrCyQHeCIREz2hFByrCZ2xF4oJ/view?usp=sharing",
          image: "/images/Catalogue/Soti/managing-mobility-for-retail.webp",
        },
      ],
    },
  ]);
  const [datalogic, setDatalogic] = useState([
    {
      Titre: "M3 mobile",
      catalogue: [
        {
          Titre: "Terminaux mobiles",
          href: "https://drive.google.com/file/d/13kqJv8IKLEr2eVxbfoTSs7QOiyvMIvXe/view?usp=sharing",
          image: "/images/Catalogue/M3mobile/terminaux-m3.webp",
        },
      ],
    },
  ]);
  const [wms, setWms] = useState([
    {
      Titre: "WMS Magistor",
      catalogue: [
        {
          Titre: "WMS Magistor",
          href: "https://drive.google.com/file/d/1-CcaNSmIDlfaTM1bqsPIH3Ii12vj_LpS/view?usp=sharing",
          image: "/images/Catalogue/Magistor/magistor.webp",
        },
      ],
    },
  ]);
  const [visualStock, setVisualStock] = useState([
    {
      Titre: "VISUAL STOCK",
      catalogue: [
        {
          Titre: "VISUAL STOCK",
          href: "https://drive.google.com/file/d/1vOvE5f1kcA3klib37hMvdojIellxYgzE/view?usp=sharing",
          image: "/images/Catalogue/VisualStock/Logo-VS.webp",
        },
      ],
    },
  ]);
  const [list, setList] = useState(null);
  const handleImageClick = (list) => {
    setList(list);
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ marginTop: "70px" }}>
      {list != null ? (
        <div style={{ margin: "20px" }}>
          {list.map((item, index) => (
            <div
              key={index}
              className="item-container"
              style={{ marginBottom: "40px" }}
            >
              <a
                className="btn btn-primary btn-sm pointer font-weight-bold"
                onClick={() => handleImageClick(null)}
              >
                <SkipPreviousIcon fontSize="small" /> revenir à l'accueil
              </a>
              <h3 className="h3-custom my-4">
                Les brochures associées à {item.Titre}:
              </h3>
              <div className="row">
                {item.catalogue.map((catalogueItem, catalogueIndex) => (
                  <div key={catalogueIndex} className="col-lg-4 col-6 p-0 my-2">
                    <a
                      href={catalogueItem.href}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-primary"
                    >
                      <div className="image-item m-2" data-aos="zoom-in">
                        {catalogueItem.image != "" && <img
                          className="image-item-img"
                          src={catalogueItem.image}
                          alt={catalogueItem.Titre}
                        />}
                        
                        <span className="image-span">
                          {catalogueItem.Titre}{" "}
                          <DownloadIcon fontSize="small" />
                        </span>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div class="image-grid-container">
            <div class="image-grid-item">
              <div class="image-card" onClick={() => handleImageClick(zebra)}>
                <img src="/images/ProduitPage/img/1.webp" alt="Logo Zebra" />
              </div>
            </div>
            <div class="image-grid-item">
              <div
                class="image-card"
                onClick={() => handleImageClick(honeywell)}
              >
                <img
                  src="/images/ProduitPage/img/2.webp"
                  alt="Logo Honeywell"
                />
              </div>
            </div>
            <div class="image-grid-item">
              <div
                class="image-card"
                onClick={() => handleImageClick(visualStock)}
              >
                <img
                  src="/images/Catalogue/VisualStock/Logo-VS.webp"
                  alt="Logo Visual stock"
                />
              </div>
            </div>
            <div class="image-grid-item">
              <div class="image-card" onClick={() => handleImageClick(wms)}>
                <img
                  src="/images/Catalogue/Magistor/savoye.webp"
                  alt="Logo WMS"
                />
              </div>
            </div>
            <div class="image-grid-item">
              <div class="image-card" onClick={() => handleImageClick(soti)}>
                <img src="/images/Partenaires/SOTI2.webp" alt="Logo Soti" />
              </div>
            </div>
            <div class="image-grid-item">
              <div
                class="image-card"
                onClick={() => handleImageClick(datalogic)}
              >
                <img
                  src="/images/Catalogue/M3mobile/m3-mobile.webp"
                  alt="Logo M3 mobile"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
