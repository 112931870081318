import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardDisplay from '../../components/CardDisplay' 
import ReferencesWidget from '../../components/solutions/ReferencesWidget';
import ProjetWidget from '../../components/solutions/ProjetWidget';
import OwlCarouselX4 from '../../components/OwlCarouselX4';
import { Helmet } from 'react-helmet';

export default function Sante(props) {

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  
  const DisplayOptions = {
    title: "Solutions pour les hôpitaux et laboratoires pharmaceutiques",
    image:"images/Solutions/Sante/healthcare-actionable-intelligence.webp",
    description: "<div> <p>Avec la pandémie du COVID 19, l'hôpital a été la cible d'une attention particulière par l'opinion publique et les instances gouvernementales.La Santé du citoyen étant devenue plus centrale que jamais. Les questions de traçabilité et d'efficacité se posent désormais avec acuité et interpellent les professionnels de la santé.<br>La mobilité clinique consiste à équiper le personnel soignant de dispositifs de collecte automatique des données, afin d'améliorer la communication entre praticien, les collaborateurs, les infirmiers et l'administration. Ces dispositifs contribuent à éviter les erreurs médicales (70% de ces erreurs est attribué au manque de rigueur dans la communication entre collaborateurs en milieu hospitalier).<br>Dans une démarche de mobilité clinique, l'hôpital exige des équipements d'identification (imprimantes de bracelets et badges pour malades et visiteurs) pouvant supporter les nettoyages multiples, ainsi que les désinfectants UV ou Solvants.<br>Le praticien a également besoin de collecte de données (Terminaux portables avec lecture code à barres, fonctions Push to Talk et capacité à résister aux bacteries) pour pouvoir consulter les dossiers patients, communiquer les bonnes informations et s'assurer que les traitements ont été correctement administrés.<br>Pour les laboratoires pharmaceutiques, la logistique est également devenue centrale. Il s'agit de pouvoir acheminer le bon médicament, au bon centre de soins et dans les bonnes quantités.<br>Dans ce cadre, TELEDYNE accompagne les professionnels de la santé dans la mise en place de solutions de traçabilité en entrepôt et en centre de soins.<br>&nbsp;</p></div>", 
};


  const imageList = ["images/Solutions/Sante/77.webp",
    "images/Solutions/Sante/78.webp",
    "images/Solutions/Sante/76.webp",
    "images/Solutions/Sante/30.webp",
    "images/Solutions/Sante/21.webp", 
];

  const dataList = [
    { lien: "#test", promo: false, image: 'images/Solutions/Sante/Imprimante-ZEBRA-ZD510-HC.webp', title: 'Imprimante ZEBRA ZD510-HC', description: "Imprimante de bracelets qualifié HC (Health Care) avec boîtier résistant aux UV et aux désinfectants" },
    { lien: "#test", promo: false, image: '/images/Solutions/Sante/ec55-photography-product-front-facing-no-shadow-1280x1280.webp', title: 'Terminal ZEBRA EC55', description: "Smartphone Industriel qualifié HC (Health Care) auto-nettoyant et traité anti-germes" },
    { lien: "#test", promo: false, image: 'images/Solutions/Sante/DS3608-HPDS3678-HP-1D2D-ULTRA-RUGGED-SCANNER.webp', title: 'Scanners Code à barres ZEBRA DS36xx', description: "Gamme de Scanners Industriels avec capacité de lecture ultra perfectionée " },
    { lien: "#test", promo: false, image: 'images/Solutions/Sante/Tablette-ZEBRA-ET56.webp', title: ' Tablette ZEBRA ET56', description: "La tablette robuste, multifonctions pour l'accès à distance aux ERP et applications métier " },
    
    { lien: "#test", promo: false, image: 'images/Solutions/Sante/infrastructure-wifi-points-d-acces.webp', title: 'Installation WIFI ', description: "Solution WIFI et Hot Spot avec supervision distribuée et monitoring à distance " },
    { lien: "#test", promo: false, image: 'images/Solutions/Sante/4-SAV.webp', title: '  Maintenance et SAV ', description: "équipements fournis avec extension de garantie à 3 ou 5 ans, pour éviter toute interruption de service "},
 ]


  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Nos solutions digitaux| Solutions pour les hôpitaux et laboratoires pharmaceutiques</title>
        <meta
          name="description"
          content="La crise du COVID-19 a mis en avant la nécessité d'améliorer la traçabilité et l'efficacité dans les hôpitaux. La mobilité clinique permet de collecter automatiquement les données pour améliorer la communication entre le personnel soignant. TELEDYNE fournit des équipements robustes, tels que des imprimantes d'identification et des terminaux portables pour la collecte de données, ainsi que des solutions de traçabilité en entrepôt et en centre de soins pour les laboratoires pharmaceutiques."
        />
      </Helmet>
      <div style={{ marginTop: 65 }}>
        <CardDisplay  {...DisplayOptions}></CardDisplay>
        <Grid container spacing={2} alignItems="center" justifyContent="center" >
        <Grid item xs={11} md={9}> 
            <OwlCarouselX4 h1="Produits phares et services proposés dans la santé" list={dataList}></OwlCarouselX4>
          </Grid>
          <Grid item xs={11} md={9}> 
            <ReferencesWidget list={imageList}></ReferencesWidget>
          </Grid>
          <Grid item xs={11} md={9}> 
          </Grid>


        </Grid>
      </div>

    </>
  );
}