import axios from "axios";

//export const BASE_URL = 'https://localhost:7011/';
export const BASE_URL = 'https://teledyne.ma/';
export const ENDPOINTS = {
    partenaire: 'partenaire',
    produit: 'produit',
    service: 'service',
    article: 'article',
    catalogue: 'catalogue',
    descriptionProduit: 'DescriptionProduit',
    GetProduits: 'article/GetProduits',
    GetServices: 'article/GetServices',
    GetSolutions: 'article/GetSolutions',
    societe: 'societe',
    SendingEmail: 'SendingEmail',
}

export const createAPIEndpoint = endpoint => {
    let url = BASE_URL + endpoint + '/';
    return {
        fetch: () => axios.get(url),
        fetchById: id => axios.get(url + id),
        fetchByName: name => axios.get(url + name),
        post: newRecord => axios.post(url, newRecord),
        put: (id, updatedRecord) => axios.put(url + id, updatedRecord),
        delete: id => axios.delete(url + id),
    }
}