import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./css/solutions/Distribution.css";

export default function DistributionWidget(props) {
  const carouselOptions = {
    margin: 10,
    nav: true,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    slideBy: "page",
    responsive: {
      0: {
        items: 1,
        rows: 1, //custom option not used by Owl Carousel, but used by the algorithm below
      },
      768: {
        items: 2,
        rows: 2, //custom option not used by Owl Carousel, but used by the algorithm below
      },
      991: {
        items: 2,
        rows: 2, //custom option not used by Owl Carousel, but used by the algorithm below
      },
    },
  };

  return (
    <>
      <div className="fragment_8824">
        <h3 className="text-primary fw-bold mb-20 mt-4 mb-4 h2-custom"> {props.h1} </h3>
        <OwlCarousel classNameName="owl-theme" {...carouselOptions}>
          {props.list.map((item) => (
            <div className="slide mx-2">
              <a >
                <div className="product_item">
                  {item.promo && (
                    <div className="product_sale">
                      <p className="promo">Promo</p>
                    </div>
                  )}
                  <div className="product_image">
                    <img src={item.image} alt={item.title} />
                  </div>
                  <div className="product_values">
                    <div className="product_title">
                      <h5 className="text-primary font-weight-bold">
                        {item.title}
                      </h5>
                    </div>
                    <div className="product_desc">
                      <p className="truncate">{item.description}</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </>
  );
}
